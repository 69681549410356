import React from "react";
import { useState } from "react";
import { useEffect } from "react";

interface HeaderBannerProps {
  title: string;
  message?: string;
  type: "info" | "danger" | "success" | "warning";
}

const HeaderBanner = ({ title, message, type }: HeaderBannerProps) => {
  const [bgClass, setBgClass] = useState("bg-secondary");

  useEffect(() => {
    if (type === "danger") {
      setBgClass("bg-danger");
    } else if (type === "info") {
      setBgClass("bg-secondary");
    } else if (type === "warning") {
      setBgClass("bg-warning");
    } else {
      setBgClass("bg-success");
    }
  }, [type]);

  return (
    <div
      className={`d-flex align-items-center p-3 my-3 text-white rounded shadow-sm ${bgClass}`}
    >
      <div className="lh-1">
        <h1 className="h6 mb-0 text-white lh-1">{title}</h1>
        {message && <small>{message}</small>}
      </div>
    </div>
  );
};

export default HeaderBanner;
