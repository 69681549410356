import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
// import { ChevronDown, ChevronUp, Edit2 } from "react-feather";
import { Button, Card, CardBody, CardHeader, Collapse } from "reactstrap";

interface AccordionItemProps {
  LeftNavigation?: React.ReactNode;
  RightNavigation?: React.ReactNode;
  handleToggle: (id: string) => void;
  title: string;
  titleColor?: string;
  open: string;
  id: string;
  body?: React.ReactNode;
}

const CustomAccordionItem = ({
  LeftNavigation,
  RightNavigation,
  handleToggle,
  title,
  titleColor,
  open,
  id,
  body,
}: AccordionItemProps) => {
  return (
    <Card className="mb-4">
      <CardHeader
        className="d-flex border-bottom"
        onClick={() => handleToggle(id)}
      >
        <>
          <div className="d-flex justify-content-start">
            {LeftNavigation && <span className="me-2">{LeftNavigation}</span>}
            <h5 className={`mb-0 ${titleColor}`}>{title}</h5>
          </div>
          <div className="d-flex justify-content-end align-items-center">
            {RightNavigation && <span>{RightNavigation}</span>}
            <span className="">
              {open === id ? (
                <Button className="mb-0" color="lightgrey" type="button" block>
                  <FontAwesomeIcon icon={faChevronUp} />
                </Button>
              ) : (
                <Button className="mb-0" color="lightgrey" type="button" block>
                  <FontAwesomeIcon icon={faChevronDown} />
                </Button>
              )}
            </span>
          </div>
        </>
      </CardHeader>
      <Collapse isOpen={open === id}>
        <CardBody>{body}</CardBody>
      </Collapse>
    </Card>
  );
};

export default CustomAccordionItem;
