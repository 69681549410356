import { Modal, Spinner } from "react-bootstrap";
import React from "react";

interface AppModalProps {
  title: string;
  subtitle?: string;
  children?: JSX.Element;
  show: boolean;
  handleHide: () => void;
  isLoading?: boolean;
}

const AppModal = ({
  title,
  subtitle,
  show,
  handleHide,
  children,
  isLoading = false,
}: AppModalProps) => {
  return (
    <Modal show={show} onHide={handleHide} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          {title} {subtitle}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading ? (
          <div className="d-flex justify-content-center">
            <Spinner animation="border" />
          </div>
        ) : (
          children
        )}
      </Modal.Body>{" "}
    </Modal>
  );
};

export default AppModal;
