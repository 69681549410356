import { Field, Form, Formik } from "formik";
import { Button, Col, Row } from "reactstrap";
import PageTitle from "../components/Common/PageTitle";
import AppTextInput from "../components/forms/AppTextInput";
import * as Yup from "yup";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  createUser,
  getRoles,
  getUser,
  updatePassword,
  updateUser,
} from "../../services/userService";
import Message from "../../utility/notifications/Message";
import { useContext, useEffect, useState } from "react";
import Notify from "../../utility/notifications/Notify";
import AppCheckboxGroupInput from "../components/forms/AppCheckboxGroupInput";
import { FormCheck } from "react-bootstrap";
import { getProjects } from "../../services/projectService";
import AppSelectInput from "../components/forms/AppSelectInput";
import AppCheckboxInput from "../components/forms/AppCheckboxInput";
import { TenantContext } from "../../context/tenantContext";

import AppButton from "../components/Common/AppButton";
import routeNames from "../../router/routeNames";
import { isHostUser } from "../../utility/Utils";

const validationSchema = Yup.object().shape({
  id: Yup.number(),
  userName: Yup.string().required().label("Username"),
  name: Yup.string().required().label("Firstname"),
  surname: Yup.string().required().label("Lastname"),
  emailAddress: Yup.string().required().label("Emailaddress"),
  password: Yup.string().when(["id", "hasCustomAuthenticationFlow"], {
    is: (id, hasCustomAuthenticationFlow) => !id && hasCustomAuthenticationFlow,
    then: Yup.string().required().label("Password"),
  }),
  passwordConfirmation: Yup.string().when(
    ["id", "hasCustomAuthenticationFlow"],
    {
      is: (id, hasCustomAuthenticationFlow) =>
        !id && hasCustomAuthenticationFlow,
      then: Yup.string()
        .required("Repeat password is required")
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .label("Repeat password"),
    }
  ),
});

const validationSchemaPassword = Yup.object().shape({
  userId: Yup.number(),
  adminPassword: Yup.string().required().label("Admin password"),
  newPassword: Yup.string().required().label("Password"),
  newPasswordConfirmation: Yup.string()
    .when("id", {
      is: (id) => !id,
      then: Yup.string()
        .required()
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
    })
    .label("Repeat password"),
});

const CreateOrEditUser = () => {
  const [isSavingUser, setIsSavingUser] = useState(false);
  const [projects, setProjects] = useState();
  const [user, setUser] = useState();
  const [roles, setRoles] = useState([]);

  const { tenant } = useContext(TenantContext);

  const { id } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    async function fetchUsers(id) {
      const result = await getUser(id);

      if (result) {
        setUser(result.data.result);
      }
    }

    async function fetchRoles() {
      const roles = await getRoles();
      if (roles) setRoles(roles.data.result.items);
    }

    async function fetchProjects() {
      const result = await getProjects();

      if (result) {
        const convertedData = result.data.result.items.map((project) => ({
          value: project.id,
          label: project.name,
        }));

        setProjects(convertedData);
      }
    }

    if (id) {
      fetchUsers(id);
    }

    fetchRoles();
    fetchProjects();
  }, [id]);

  const handleSubmit = async (user) => {
    setIsSavingUser(true);

    if (user.id) {
      await updateUser(user)
        .then((res) => {
          if (res.status === 200) {
            setIsSavingUser(false);
            Notify.success(`User ${user.name} has been updated`);
            navigate(routeNames.users);
          }
        })
        .catch((err) => {
          if (err.response) {
            setIsSavingUser(false);

            Message(
              err.response.data.error.message,
              err.response.data.error.details,
              "error"
            );
          }
        });
    } else {
      await createUser(user)
        .then((res) => {
          if (res.status === 200) {
            setIsSavingUser(false);
            Notify.success(`User ${user.name} has been created`);
            navigate(routeNames.users);
          }
        })
        .catch((err) => {
          if (err.response) {
            setIsSavingUser(false);

            Message(
              err.response.data.error.message,
              err.response.data.error.details,
              "error"
            );
          }
        });
    }
  };

  const handlePasswordChangeSubmit = async (pwReset) => {
    setIsSavingUser(true);

    await updatePassword(pwReset)
      .then((res) => {
        if (res.status === 200) {
          setIsSavingUser(false);
          Notify.success(`Password for user ${user.name} has been updated`);
          navigate(routeNames.users);
        }
      })
      .catch((err) => {
        if (err.response) {
          setIsSavingUser(false);

          Message(
            err.response.data.error.message,
            err.response.data.error.details,
            "error"
          );
        }
      });
  };

  return (
    <>
      <PageTitle title={id ? "Update user" : "Configure user"} />

      <Formik
        enableReinitialize={true}
        initialValues={
          user
            ? {
                userName: user.userName,
                name: user.name,
                surname: user.surName,
                emailAddress: user.emailAddress,
                roleNames: user.roleNames ?? [],

                id: user.id,
                isActive: user.isActive,
                projectIds: user.linkedProjects
                  ? user.linkedProjects.map((a) => a.projectId)
                  : [],
                hasCustomAuthenticationFlow:
                  tenant?.hasCustomAuthenticationFlow || false,
              }
            : {
                userName: "",
                name: "",
                surname: "",
                emailAddress: "",
                isActive: true,
                roleNames: [],
                projectIds: [],
                hasCustomAuthenticationFlow:
                  tenant?.hasCustomAuthenticationFlow || false,
              }
        }
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, values }) => (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col lg={6} sm={12}>
                <div className="mb-3">
                  <AppTextInput type="text" name="userName" label="Username" />
                </div>
              </Col>
              <Col lg={6} sm={12}>
                <div className="mb-3">
                  <AppTextInput
                    type="email"
                    name="emailAddress"
                    label="Emailaddress"
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg={6} sm={12}>
                <div className="mb-3">
                  <AppTextInput type="text" name="name" label="Firstname" />
                </div>
              </Col>
              <Col lg={6} sm={12}>
                <div className="mb-3">
                  <AppTextInput type="text" name="surname" label="Lastname" />
                </div>
              </Col>
            </Row>

            {!values.id && tenant?.hasCustomAuthenticationFlow && (
              <>
                <Row>
                  <Col lg={6} sm={12}>
                    <div className="mb-3">
                      <AppTextInput
                        type="password"
                        name="password"
                        label="Password"
                      />
                    </div>
                  </Col>
                  <Col lg={6} sm={12}>
                    <div className="mb-3">
                      <AppTextInput
                        type="password"
                        name="passwordConfirmation"
                        label="Repeat password"
                      />
                    </div>
                  </Col>
                </Row>
              </>
            )}

            <Row>
              <Col sm={12}>
                <AppCheckboxInput name={`isActive`} label={"Is active"} />
              </Col>
            </Row>

            <Row className="mb-2">
              <Col lg={6}>
                {roles && <h6>Select role(s)</h6>}

                {roles &&
                  roles.map((item, i) => {
                    return (
                      <FormCheck key={item.id}>
                        <Field
                          component={AppCheckboxGroupInput}
                          name="roleNames"
                          value={item.name}
                          label={item.displayName}
                        />
                      </FormCheck>
                    );
                  })}
              </Col>
            </Row>

            {!isHostUser() && (
              <>
                {projects && projects.length > 0 && (
                  <>
                    <Row className="mb-2 mt-3">
                      <h6>Select the projects the user needs access to</h6>
                      <Col lg={6}>
                        <AppSelectInput
                          isMultiple={true}
                          name="projectIds"
                          options={projects}
                          selectedOptions={values.projectIds}
                        />
                      </Col>
                    </Row>
                  </>
                )}
              </>
            )}

            <Row className="text-end">
              <Col>
                <Link to={routeNames.users}>
                  <AppButton
                    title="Cancel"
                    variant="cancel"
                    buttonSize="lg"
                    type="submit"
                    className="me-3"
                  />
                </Link>
                <AppButton
                  title={values.id ? "Update user" : "Create user"}
                  variant="save"
                  buttonSize="lg"
                  type="submit"
                  loading={isSavingUser}
                  disabled={isSavingUser}
                />
              </Col>
            </Row>
          </Form>
        )}
      </Formik>

      <Formik
        enableReinitialize={true}
        initialValues={{
          userId: id,
        }}
        onSubmit={handlePasswordChangeSubmit}
        validationSchema={validationSchemaPassword}
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            {id && (
              <>
                <hr></hr>

                <PageTitle title="Update password" customStyles="h4" />

                <Row>
                  <Col lg={6} sm={12}>
                    <div className="mb-3">
                      <AppTextInput
                        type="password"
                        name="adminPassword"
                        label="Your password"
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col lg={6} sm={12}>
                    <div className="mb-3">
                      <AppTextInput
                        type="password"
                        name="newPassword"
                        label="Password"
                      />
                    </div>
                  </Col>
                  <Col lg={6} sm={12}>
                    <div className="mb-3">
                      <AppTextInput
                        type="password"
                        name="newPasswordConfirmation"
                        label="Repeat password"
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="text-end">
                  <Col>
                    <AppButton
                      title="Update password"
                      variant="save"
                      buttonSize="lg"
                      type="submit"
                      loading={isSavingUser}
                      disabled={isSavingUser}
                    />
                  </Col>
                </Row>
              </>
            )}
          </Form>
        )}
      </Formik>
    </>
  );
};

export default CreateOrEditUser;
