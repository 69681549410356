import { useFormikContext } from "formik";
import React from "react";
import { Button } from "react-bootstrap";
import * as _ from "lodash";

interface SubmitButtonProps {
  saving: boolean;
  title: string;
}

export const SubmitButton = ({ saving, title }: SubmitButtonProps) => {
  const { handleSubmit, setTouched, errors } = useFormikContext();

  const getTouchedObj = (errors: any) => {
    if (!errors) return {};

    const touched: any = {};
    Object.keys(errors).forEach((key) => {
      if (Array.isArray(errors[key])) {
        touched[key] = errors[key].map((val: any) => getTouchedObj(val));
      } else if (typeof errors[key] === "object") {
        touched[key] = getTouchedObj(errors[key]);
      } else {
        touched[key] = true;
      }
    });

    return touched;
  };

  return (
    <Button
      color="primary"
      size="lg"
      type="button"
      className="align-self-end"
      disabled={saving}
      onClick={() => {
        setTouched(getTouchedObj(errors));

        if (_.isEmpty(errors)) {
          handleSubmit();
        }
      }}
    >
      {title}
    </Button>
  );
};
