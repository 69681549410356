import { Formik } from "formik";
import { Col, Form, Modal, Row } from "react-bootstrap";
import {
  StaypleaseItemConfigurationListItem,
  UpdateStayPleaseItemInput,
} from "../../types";
import AppModal from "../components/Common/AppModal";
import * as Yup from "yup";
import AppTextInput from "../components/forms/AppTextInput";
import AppButton from "../components/Common/AppButton";
import { useState } from "react";
import { updateConfigurationItem } from "../../services/stayPleaseService";
import Notify from "../../utility/notifications/Notify";
import Message from "../../utility/notifications/Message";

interface EditProps {
  configItem?: StaypleaseItemConfigurationListItem;
  show: boolean;
  handleHide: () => void;
  handleUpdated: (input: UpdateStayPleaseItemInput) => void;
}

const validationSchema = Yup.object().shape({
  id: Yup.number().required(),
  code: Yup.number().required().label("Code"),
});

const EditItemConfiguration = ({
  configItem,
  show,
  handleHide,
  handleUpdated,
}: EditProps) => {
  const [saving, setSaving] = useState(false);

  const handleSubmit = async (item: UpdateStayPleaseItemInput) => {
    setSaving(true);

    await updateConfigurationItem(item)
      .then((res) => {
        if (res.status === 200) {
          setSaving(false);
          Notify.success(`Configuratie ${configItem?.name} has been updated`);
          handleHide();
          handleUpdated(item);
        }
      })
      .catch((err) => {
        if (err.response) {
          setSaving(false);

          Message(
            err.response.data.error.message,
            err.response.data.error.details,
            "error"
          );
        }
      });
  };

  if (!configItem) return null;

  return (
    <AppModal
      handleHide={handleHide}
      show={show}
      title={`Edit ${configItem.name}`}
    >
      <Row>
        <Formik
          enableReinitialize={true}
          initialValues={{
            id: configItem!.id,
            code: configItem?.code || 0,
          }}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col lg={12}>
                  <div className="mb-3">
                    <AppTextInput type="number" name="code" label="Code" />
                  </div>
                </Col>
              </Row>

              <Row className="text-end">
                <Col>
                  <AppButton
                    title="Cancel"
                    variant="cancel"
                    buttonSize="lg"
                    type="button"
                    className="me-3"
                    onClick={() => handleHide()}
                  />
                  <AppButton
                    title="Save"
                    variant="save"
                    buttonSize="lg"
                    type="submit"
                    loading={saving}
                    disabled={saving}
                  />
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Row>
    </AppModal>
  );
};

export default EditItemConfiguration;
