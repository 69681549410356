import React from "react";
import { Outlet } from "react-router-dom";
import { ReactComponent as ReactLogo } from "../../assets/media/logo-color.svg";

const AuthLayout = () => {
  return (
    <div className="p-5">
      <div className="form-signin">
        <div className="text-center">
          <ReactLogo width={150} className="mb-5" />
        </div>
        <Outlet />
      </div>
    </div>
  );
};

export default AuthLayout;
