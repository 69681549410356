import { useState } from "react";
import moment from "moment";

// Define an interface for the hook's state to allow both number and null types
interface DateInfo {
  selectedYear: number | null;
  selectedWeek: number | null;
  weekPart: number | null;
}

const useWeekPart = () => {
  const [dateInfo, setDateInfo] = useState<DateInfo>({
    selectedYear: null,
    selectedWeek: null,
    weekPart: null,
  });

  const calculateWeekPart = (selectedDate: any) => {
    const selectedMoment = moment(selectedDate);
    const selectedYear = selectedMoment.year();
    const selectedWeek = selectedMoment.isoWeek();
    const startOfWeek = selectedMoment.clone().startOf("isoWeek");
    let weekPart = 1; // Default to weekPart 1

    for (let day = 0; day <= 6; day++) {
      const dayMoment = startOfWeek.clone().add(day, "days");
      if (dayMoment.month() !== startOfWeek.month()) {
        weekPart =
          selectedMoment.date() >= dayMoment.date() &&
          selectedMoment.month() === dayMoment.month()
            ? 2
            : 1;
        break;
      }
    }

    setDateInfo({
      selectedYear,
      selectedWeek,
      weekPart,
    });
  };

  return [dateInfo, calculateWeekPart] as const; // Use 'as const' to make the return type a readonly tuple
};

export default useWeekPart;
