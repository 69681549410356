import {
  GetAdditionalRequestData,
  ProcessAdditionalRequestInput,
} from "./../types/index";
import http from "./httpService";

const apiEndpoint = "/services/app/AdditionalRequest";

export function getAdditionalRequest(
  projectId: number,
  week: number,
  weekPart: number,
  year: number
) {
  return http.get<GetAdditionalRequestData>(
    apiEndpoint + "/GetAdditionalRequest",
    {
      params: {
        projectId,
        week,
        weekPart,
        year,
      },
    }
  );
}

export function submitAdditionalRequestData(
  input: ProcessAdditionalRequestInput
) {
  return http.post<GetAdditionalRequestData>(
    apiEndpoint + "/ProcessAdditionalRequest",
    input
  );
}
