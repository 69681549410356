import React, { useRef } from "react";
import "flatpickr/dist/themes/light.css";

import Flatpickr from "react-flatpickr";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";

interface DatePickerFilterProps {
  dateValue?: Date;
  handleChange: (date: Date) => void;
  classNames?: string;
}

const CustomInput = ({
  defaultValue,
  inputRef,
  customClassnames,
  ...props
}) => {
  return (
    <button
      type="button"
      className={`btn btn-lg btn-secondary ${customClassnames}`}
      {...props}
      defaultValue={defaultValue}
      ref={inputRef}
    >
      <FontAwesomeIcon icon={faCalendar} />
    </button>
  );
};

const DatePickerFilter = ({
  handleChange,
  dateValue,
  classNames,
}: DatePickerFilterProps) => {
  const fp = useRef(null);

  return (
    <Flatpickr
      value={dateValue}
      onChange={([date]) => {
        // Set the time to the start of the day (00:00:00)
        const adjustedDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours() + 4);
        handleChange(adjustedDate);
      }}
      options={{
        locale: {
          firstDayOfWeek: 1,
        },
              // Add time_24hr: false to disable time selection
              time_24hr: false,
              // Additional option to ensure only date is selectable
              enableTime: false,
      }}
      render={({ defaultValue, value, ...props }, ref) => {
        return (
          <CustomInput
            defaultValue={defaultValue}
            inputRef={ref}
            customClassnames={classNames}
          />
        );
      }}
    />
  );
};

export default DatePickerFilter;
