import { saveAs } from "file-saver";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import routeNames from "../../router/routeNames";
import { getUserRolesForTenant } from "../../services/UserExportService";
import { getTenants } from "../../services/tenantService";
import AppButton from "../components/Common/AppButton";
import PageTitle from "../components/Common/PageTitle";
import { TenantDto } from "../../types/apiTypes";

const Tenants = () => {
  const [tenants, setTenants] = useState<TenantDto[] | undefined | null>();

  useEffect(() => {
    async function fetchTenants() {
      const response = await getTenants();
      setTenants(response.data.result.items);
    }
    fetchTenants();
  }, []);

  const handleDownload = async (tenant) => {
    await getUserRolesForTenant(tenant.id).then((result) => {
      saveAs(new Blob([result.data]), `User-roles-${tenant.name}.xlsx`);
    });
  };

  return (
    <div>
      <>
        <PageTitle title="Tenant overview" />
        {tenants && (
          <Row>
            <DataTable
              pagination
              responsive
              columns={[
                {
                  name: "Tenancyname",
                  selector: (row) => row.tenancyName,
                  sortable: true,
                  cell: (row) => (
                    <Link to={`${routeNames.tenant_edit}/${row.id}`}>
                      {row.tenancyName}
                    </Link>
                  ),
                },
                {
                  name: "Tenant",
                  selector: (row) => row.name,
                  sortable: true,
                },
                {
                  name: "Is active",
                  sortable: true,
                  cell: (row) => (row.isActive ? "Yes" : "No"),
                },
                {
                  name: "Actions",
                  cell: (row) => (
                    <>
                      <AppButton
                        onClick={() => handleDownload(row)}
                        size="sm"
                        className="ms-1"
                        title="Download user matrix"
                        variant="download"
                        color="primary"
                      />
                    </>
                  ),
                },
              ]}
              data={tenants}
            />
          </Row>
        )}
        <Row>
          <Col>
            <Link to={routeNames.tenant_create}>
              <Button type="button" color="primary" size="lg">
                Create new tenant
              </Button>
            </Link>
          </Col>
        </Row>
      </>
    </div>
  );
};

export default Tenants;
