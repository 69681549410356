import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import UserContext from "../../context/userContext";
import routeNames from "../../router/routeNames";
import useJwt from "../../services/useJwt";

const Logout = () => {
  const userContext = useContext(UserContext);

  const navigate = useNavigate();
  useEffect(() => {
    useJwt.logout();

    userContext.onLoggedOut();

    navigate(routeNames.login);
  }, []);
  return null;
};

export default Logout;
