import React, { useEffect, useState } from "react";
import AppModal from "../Common/AppModal";
import { ReleaseNoteEditDto } from "../../../types";
import {
  getLastReleaseNote,
  markAsRead,
} from "../../../services/releaseNoteService";
import ReleaseNoteDetailsComponent from "./ReleaseNoteDetails";
import AppButton from "../Common/AppButton";

interface ReleaseNoteModalProps {
  show: boolean;
  handleHide: () => void;
}

const ReleaseNoteModal = ({ show, handleHide }: ReleaseNoteModalProps) => {
  const [releaseNote, setReleaseNote] =
    useState<ReleaseNoteEditDto["result"]>();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!show) return;
    async function fetchReleaseNote() {
      setLoading(true);
      const result = await getLastReleaseNote();

      setLoading(false);
      if (result) {
        setReleaseNote(result.data.result);
      }
    }

    fetchReleaseNote();
  }, [show]);

  return (
    <AppModal
      show={show}
      handleHide={async () => {
        await markAsRead();
        handleHide();
      }}
      title="System update"
      isLoading={loading}
    >
      <>
        {releaseNote && (
          <ReleaseNoteDetailsComponent releaseNote={releaseNote} />
        )}

        <AppButton
          title="Close"
          variant="cancel"
          onClick={async () => {
            await markAsRead();
            handleHide();
          }}
        />
      </>
    </AppModal>
  );
};

export default ReleaseNoteModal;
