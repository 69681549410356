import {
  ImpersonateInput,
  ResetPasswordInput,
  SwitchUserInput,
} from "./../types/index";
import { SendPasswordResetCodeInput } from "../types/index";
import http from "./httpService";

const apiEndpoint = "/services/app/Account";

export function sendPasswordResetCode(input: SendPasswordResetCodeInput) {
  return http.post(apiEndpoint + "/SendPasswordResetCode", input);
}

export function resetPassword(input: ResetPasswordInput) {
  return http.post(apiEndpoint + "/ResetPassword", input);
}

export function impersonate(input: ImpersonateInput) {
  return http.post(apiEndpoint + "/Impersonate", input);
}

export function backToImpersonator() {
  return http.post(apiEndpoint + "/BackToImpersonator");
}

export function getSwitchList() {
  return http.get(apiEndpoint + "/GetTenantSwitchList");
}

export function SwitchUser(input: SwitchUserInput) {
  return http.post("/TokenAuth/SwitchToLinkedAccountSignIn", input);
}
