import {
  GetCitizenMSignOffData,
  GetCitizenMWeeklySignOffData,
  GetStaypleaseDailyCorrectionsOutput,
  GetStaypleaseDailyCorrectionsRoomNumbersOutput,
  GetStaypleaseDailyOutput,
  ProcessStayPleaseDailyInput,
  SubmitCitinMWeeklyData,
  SubmitSignOffData,
  UpdateStayPleaseItemInput,
} from "./../types/index";
import http from "./httpService";

const apiEndpoint = "/services/app/Stayplease";
const apiEndpointStaypleaseExport = "/services/app/StaypleaseDailyExport";
const apiEndpointStayplease = "/services/app/StaypleaseDaily";
const apiEndpointStaypleaseWeekly = "/services/app/StayPleaseWeeklyOverview";
const apiEndpointItemConfiguration = "/services/app/StaypleaseItem";
const apiEndpointStayPleaseOverview = "/services/app/StayPleaseOverview";

export function getStayPleaseDatabases() {
  return http.get(apiEndpoint + "/GetAllDatabases");
}

export function getStayPleaseDaily(
  projectId: number,
  date: Date,
  refreshStaypleaseData: boolean
) {
  return http.get<GetStaypleaseDailyOutput>(
    apiEndpointStayplease + "/GetStaypleaseDaily",
    {
      params: {
        projectId,
        date,
        refreshStaypleaseData,
      },
    }
  );
}

export function getStayPleaseDailyCorrections(
  projectId: number,
  date: Date,
  refreshStaypleaseData: boolean
) {
  return http.get<GetStaypleaseDailyCorrectionsOutput>(
    apiEndpointStayplease + "/GetStaypleaseOverrideDaily",
    {
      params: {
        projectId,
        date,
        refreshStaypleaseData,
      },
    }
  );
}

export function getStayPleaseDailyCorrectionsRooms(
  projectId: number,
  date: Date,
  refreshStaypleaseData: boolean
) {
  return http.get<GetStaypleaseDailyCorrectionsRoomNumbersOutput>(
    apiEndpointStayplease + "/GetStaypleaseOverrideRoomNumbers",
    {
      params: {
        projectId,
        date,
        refreshStaypleaseData,
      },
    }
  );
}

export function getStaypleaseDailyExport(projectId: number, date: string) {
  return http.get(apiEndpointStaypleaseExport + "/Export", {
    params: { projectId, date },
    headers: {
      "Content-Type": "application/json",
    },
    responseType: "blob",
    timeout: 30000,
  });
}

export function GetNightAuditReport(
  projectId: number,
  dateFrom: Date,
  dateTill: Date
) {
  return http.get(apiEndpointStaypleaseExport + "/ExportSignature", {
    params: { projectId, dateFrom, dateTill },
    headers: {
      "Content-Type": "application/json",
    },
    responseType: "blob",
    timeout: 30000,
  });
}


export function getItemConfiguration() {
  return http.get(apiEndpointItemConfiguration + "/GetAll?maxResultCount=500");
}

export function updateConfigurationItem(input: UpdateStayPleaseItemInput) {
  return http.put(apiEndpointItemConfiguration + "/update", input);
}

export function processStaypleaseDaily(input: ProcessStayPleaseDailyInput) {
  return http.post(apiEndpointStayplease + "/Process", input);
}

export function getStayPleaseDataForSignOff(projectId: number, date: string) {
  return http.get<{ result: GetCitizenMSignOffData }>(
    apiEndpointStayplease + "/GetForSignOff",
    {
      params: {
        projectId,
        date,
      },
    }
  );
}

export function processStayPleaseDataSignOff(input: SubmitSignOffData) {
  return http.post(apiEndpointStayplease + "/SignOff", input);
}

export function getStayPleaseWeeklyDataForSignOff(
  projectId: number,
  year: number,
  week: number,
  weekPart: number
) {
  return http.get<{ result: GetCitizenMWeeklySignOffData }>(
    apiEndpointStaypleaseWeekly + "/GetWeeklyOverview",
    { params: { projectId, year, week, weekPart } }
  );
}

export function processStayPleaseWeeklySignOff(input: SubmitCitinMWeeklyData) {
  return http.post(apiEndpointStayPleaseOverview + "/process", input);
}
