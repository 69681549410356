import React from "react";
import { AccordionBody, Col, Label, Row } from "reactstrap";

import approvedLogo from "../../../assets/media/circle-check-solid.png";
import outstandingLogo from "../../../assets/media/spinner.png";
import declinedLogo from "../../../assets/media/circle-xmark-solid.png";
import moment from "moment";
import {
  Additionalwork_status_types,
  Weekly_Daily_Item_Status,
} from "../../../utility/constants";

interface AppDashboardAccordionItemProps {
  date: Date | null;
  statusType: number;
  description: string;
  isAdditionalRequest: boolean;
}

const AppDashboardAccordionItem = ({
  date,
  statusType,
  description,
  isAdditionalRequest = false,
}: AppDashboardAccordionItemProps) => {
  return (
    <Row className="align-items-center mb-2">
      <Col lg={2} sm={2}>
        {date && (
          <div className="d-flex align-items-center">
            {statusType !== Weekly_Daily_Item_Status.Approved ? (
              <span className="ms-2">
                <strong>{moment(date).format("DD-MM-yyyy")}</strong>
              </span>
            ) : (
              <span className="ms-2">{moment(date).format("DD-MM-yyyy")}</span>
            )}
          </div>
        )}
        {isAdditionalRequest && (
          <div className="d-flex align-items-center">
            <span className="ms-2">
              <strong>Additional request</strong>
            </span>
          </div>
        )}
      </Col>
      {isAdditionalRequest ? (
        <Col lg={3} sm={3} className="align-items-center">
          <div className="d-flex align-items-center">
            <img
              width={20}
              src={
                statusType ===
                Additionalwork_status_types.ApprovedByHotelManager
                  ? approvedLogo
                  : statusType ===
                    Additionalwork_status_types.DeclinedByAreaManager
                  ? declinedLogo
                  : statusType ===
                    Additionalwork_status_types.DeclinedByHotelManager
                  ? declinedLogo
                  : outstandingLogo
              }
            />
                <span className="ms-2">
              {statusType === Additionalwork_status_types.ApprovedByHotelManager
                ? "Completed" : "Still in sign-off flow"}
            </span>
          </div>
        </Col>
      ) : (
        <Col lg={3} sm={3} className="align-items-center">
          <div className="d-flex align-items-center">
            <img
              width={20}
              src={
                statusType === Weekly_Daily_Item_Status.Approved
                  ? approvedLogo
                  : statusType === Weekly_Daily_Item_Status.Outstanding
                  ? outstandingLogo
                  : declinedLogo
              }
            />
            <span className="ms-2">
              {statusType === Weekly_Daily_Item_Status.Approved
                ? "Completed"
                : statusType === Weekly_Daily_Item_Status.Outstanding
                ? "Still in sign-off flow"
                : "Declined by manager"}
            </span>
          </div>
        </Col>
      )}

      <Col lg={4} sm={4}>
        <span>{!isAdditionalRequest ?  description : statusType === Additionalwork_status_types.ApprovedByHotelManager
                ? "Completed"
                : statusType ===
                  Additionalwork_status_types.ApprovedByAreaManager
                ? "Waiting for hotel manager"
                : statusType === Additionalwork_status_types.SendForReview
                ? "Waiting for area manager"
                : statusType === 0
                ? "Action needed by housekeeper"
                : statusType === Additionalwork_status_types.New
                ? "Action needed by housekeeper"
                : "Declined by manager" }</span>
      </Col>
    </Row>
  );
};

export default AppDashboardAccordionItem;
