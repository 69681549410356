import { ApiResponse } from "../types";
import { GetDailyDto, SignOffInput } from "../types/apiTypes";
import http from "./httpService";

const apiEndpoint = "/services/app/Daily";

export function getDaily(projectId, date) {
  const params = {
    projectId: projectId,
    date: date,
  };
  return http.get<ApiResponse<GetDailyDto>>(apiEndpoint + "/GetDaily", {
    params,
  });
}

export function getSignoffDaily(projectId, date) {
  const params = {
    projectId: projectId,
    date: date,
  };
  return http.get<ApiResponse<GetDailyDto>>(apiEndpoint + "/GetForSignOff", {
    params,
  });
}

export function submitSignOffDaily(input: SignOffInput) {
  return http.post(apiEndpoint + "/SignOff", input);
}

export function processDaily(dailyData) {
  return http.post(apiEndpoint + "/ProcessDaily", dailyData);
}

export function getUserProjects() {
  return http.get(apiEndpoint + "/GetProjectsForUser");
}

export function getDeclinedDailys(projectId) {
  return http.get(apiEndpoint + `/GetDeclinedDailys?projectId=${projectId}`);
}
