import routeNames from "../router/routeNames";
import {
  ApplicationSides,
  Menu_item_types,
  TenantSignOffFlow,
} from "../utility/constants";

const menuItems = [
  {
    id: "additionalHoursOverviewHM",
    title: "Approval Additional request",
    side: ApplicationSides.TENANTSIDE,
    requiredRole: "Hotel manager",
    navLink: `${routeNames.additional_hours_overview}1`,
    requiresAdditionalItems: true,
  },
  {
    id: "additionalHoursOverviewAM",
    title: "Approval Additional request (HFC)",
    side: ApplicationSides.TENANTSIDE,
    requiredRole: "HFC Area manager",
    navLink: `${routeNames.additional_hours_overview}0`,
    requiresAdditionalItems: true,
  },
  {
    id: "daily",
    title: "Daily input",
    side: ApplicationSides.TENANTSIDE,
    navLink: routeNames.daily,
    requiredRole: ["Housekeeper", "HFC Area manager"],
  },
  {
    id: "corrections",
    title: "Corrections",
    side: ApplicationSides.TENANTSIDE,
    navLink: routeNames.sp_corrections,
    requiredRole: "Housekeeper",
    isStayPlease: true,
  },
  {
    id: "additionalhours",
    title: "Additional request",
    side: ApplicationSides.TENANTSIDE,
    navLink: routeNames.additional_hours,
    requiredRole: "Housekeeper",
    requiresAdditionalItems: true,
  },
  {
    id: "overviewWeekly",
    title: "Weekly approval",
    side: ApplicationSides.TENANTSIDE,
    requiredRole: ["Hotel manager", "HFC Area manager"],
    navLink: routeNames.data_approval,
    isStayPlease: false,
  },
  {
    id: "overviewHM",
    title: "Weekly approval",
    side: ApplicationSides.TENANTSIDE,
    requiredRole: "Hotel manager",
    navLink: routeNames.cm_data_approval + "/HotelManager",
    isStayPlease: true,
  },
  {
    id: "Signoff",
    title: "Daily approval",
    side: ApplicationSides.TENANTSIDE,
    requiredRole: "MOD",
    navLink: routeNames.signoff,
    isStayPlease: false,
    requiredTenantflow: TenantSignOffFlow.Daily,
  },
  {
    id: "overviewMOD",
    title: "Daily approval",
    side: ApplicationSides.TENANTSIDE,
    requiredRole: "MOD",
    navLink: routeNames.cm_data_approval + "/MOD",
    isStayPlease: true,
  },
  {
    id: "AreaManagerDashboardHM",
    title: "Dashboard",
    side: ApplicationSides.TENANTSIDE,
    requiredRole: [
      "Hotel manager",
      "HFC Area manager",
      "Housekeeper",
      "Viewer",
    ],
    navLink: routeNames.area_manager_dashboard,
  },
  {
    id: "divider1",
    type: Menu_item_types.DIVIDER,
    side: ApplicationSides.TENANTSIDE,
    requiredRole: "Admin",
  },
  {
    id: "administration",
    title: "Administration",
    type: Menu_item_types.HEADER,
    side: ApplicationSides.TENANTSIDE,
    requiredRole: "Admin",
  },
  {
    id: "users",
    title: "Users",
    navLink: routeNames.users,
    requiredRole: ["Customer admin", "Admin"],
    side: ApplicationSides.BOTHSIDES,
  },
  {
    id: "projects",
    title: "Projects",
    navLink: routeNames.projects,
    requiredRole: "Admin",
    side: ApplicationSides.TENANTSIDE,
  },
  {
    id: "tenants",
    title: "Tenants",
    navLink: routeNames.tenants,
    requiredRole: "Admin",
    side: ApplicationSides.HOSTSIDE,
  },
  {
    id: "hostDashboard",
    title: "Dashboard",
    navLink: routeNames.hostDashboard,
    requiredRole: "Admin",
    side: ApplicationSides.HOSTSIDE,
  },
  {
    id: "dailyMatrix",
    title: "Daily Matrix",
    navLink: routeNames.matrixHostDashboard,
    requiredRole: "Admin",
    side: ApplicationSides.HOSTSIDE,
  },
  {
    id: "netSuiteLogs",
    title: "Netsuite logs",
    navLink: routeNames.afas_logs,
    requiredRole: "admin",
    side: ApplicationSides.HOSTSIDE,
  },
  {
    id: "Release notes",
    title: "Release notes",
    navLink: routeNames.release_notes,
    requiredRole: "admin",
    side: ApplicationSides.HOSTSIDE,
  },
  {
    id: "Release notes",
    title: "Release notes",
    navLink: routeNames.release_notes,
    side: ApplicationSides.TENANTSIDE,
  },
  {
    id: "spitemconfiguration",
    title: "Item configuration",
    navLink: routeNames.spitemconfiguration,
    requiredRole: "admin",
    side: ApplicationSides.TENANTSIDE,
    isStayPlease: true,
  },
];

export default menuItems;
