import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import routeNames from "../../router/routeNames";
import { getMyProjects } from "../../services/projectService";
import { ProjectListItem } from "../../types";
import ProjectSelectionPage from "../components/pages/projectSelectionPage";

const AdditionalHoursOverview = () => {
  const navigate = useNavigate();
  const [projects, setProjects] = useState<ProjectListItem[]>();
  const [searchTerm, setSearchTerm] = useState<string>("");

  const { role } = useParams();

  useEffect(() => {
    async function fetchUserProjects() {
      const projects = await getMyProjects(searchTerm);

      const projectList: ProjectListItem[] = projects.data.result;
      if (projectList && projectList.length === 1 && searchTerm == "") {
        navigate(
          `${routeNames.additional_hours_overview}${role}/${projectList[0].id}`
        );
      } else {
        setProjects(projectList);
      }
    }

    fetchUserProjects();
  }, [navigate, searchTerm]);

  return (
    <ProjectSelectionPage
      projects={projects}
      buttonText="Open additional requests"
      onProjectSelection={(id) =>
        navigate(`${routeNames.additional_hours_overview}${role}/${id}`)
      }
      onHandleSearch={(val) => {
        setSearchTerm(val);
      }}
      pageTitle={"Project overview"}
    />
  );
};

export default AdditionalHoursOverview;
