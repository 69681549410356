import http from "./httpService";

const apiEndpoint = "/services/app/WeeklyOverview";
const processEndpoint = "/services/app/Overview";

export function getWeeklyOverview(projectId, year, week, weekPart) {
  const params = {
    projectId: projectId,
    year: year,
    week: week,
    weekPart: weekPart
  };

  return http.get(apiEndpoint + "/GetWeeklyOverview", { params });
}

export function processData(data) {
  return http.post(processEndpoint + "/Process", data);
}

export function getUserProjects() {
  return http.get(apiEndpoint + "/GetProjectsForUser");
}
