import React from "react";
import { useFormikContext } from "formik";
import { Input } from "reactstrap";
import AppFormError from "./AppFormError";
import AppFormLabel from "./AppFormLabel";

function AppTextAreaInput({ name, label, required = false, ...otherProps }) {
  const { setFieldTouched, setFieldValue, values, errors, touched } =
    useFormikContext();

  return (
    <>
      {<AppFormLabel label={label} required={required} />}

      {values && (
        <Input
          value={values[name]}
          type="textarea"
          onBlur={() => setFieldTouched(name)}
          onChange={(event) => setFieldValue(name, event.target.value)}
          {...otherProps}
        />
      )}
      {!values && (
        <Input
          onBlur={() => setFieldTouched(name)}
          type="textarea"
          onChange={(event) => setFieldValue(name, event.target.value)}
          {...otherProps}
        />
      )}
      <AppFormError visible={touched[name]} error={errors[name]}></AppFormError>
    </>
  );
}

export default AppTextAreaInput;
