import { useEffect, useState } from "react";
import { SwitchTenantOutput } from "../../types";
import { getSwitchList } from "../../services/accountService";
import React from "react";
import SwitchCard from "./components/SwitchCard";
const SwitchUserOverview = () => {
  const [switchList, setSwitchList] = useState<SwitchTenantOutput>();

  useEffect(() => {
    async function fetchSwitchTenants() {
      const response = await getSwitchList();
      setSwitchList(response.data);
    }
    fetchSwitchTenants();
  }, []);

  return (
    <div
      style={{
        borderRadius: "5px",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h4>Switch to another tenant</h4>
      {switchList?.result.map((item, index) => (
        <SwitchCard key={index} data={item} />
      ))}
    </div>
  );
};

export default SwitchUserOverview;
