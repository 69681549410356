import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import * as Yup from "yup";
import routeNames from "../../router/routeNames";
import {
  createReleaseNote,
  getReleaseNote,
  updateReleaseNote,
} from "../../services/releaseNoteService";
import { ReleaseNoteEditDto } from "../../types";
import Message from "../../utility/notifications/Message";
import Notify from "../../utility/notifications/Notify";
import PageTitle from "../components/Common/PageTitle";
import AppTextInput from "../components/forms/AppTextInput";
import WYSIWYGInput from "../components/forms/WYSIWYGInput";
import AppDateInput from "../components/forms/AppDateInput";
import moment from "moment";

const validationSchema = Yup.object().shape({
  title: Yup.string().required().label("Title").max(150),
  versionNumber: Yup.string().required().label("Versionnumber").max(10),
  content: Yup.string().required().label("Content").max(8000),
});

const CreateOrEditReleaseNote = () => {
  const [releaseNote, setReleaseNote] =
    useState<ReleaseNoteEditDto["result"]>();
  const [isSavingReleaseNote, setIsSavingReleaseNote] = useState(false);

  const { id } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    async function fetchReleaseNote() {
      if (id) {
        const result = await getReleaseNote(parseInt(id || "0"));

        if (result) {
          setReleaseNote(result.data.result);
        }
      }
    }

    fetchReleaseNote();
  }, [id]);

  const handleSubmit = async (releaseNote: ReleaseNoteEditDto["result"]) => {
    setIsSavingReleaseNote(true);

    if (releaseNote.id) {
      await updateReleaseNote(releaseNote)
        .then((res) => {
          if (res.status === 200) {
            setIsSavingReleaseNote(false);
            Notify.success(`Release note has been saved`);
            navigate(routeNames.release_notes);
          }
        })
        .catch((err) => {
          if (err.response) {
            setIsSavingReleaseNote(false);

            Message(
              err.response.data.error.message,
              err.response.data.error.details,
              "error"
            );
          }
        });
    } else {
      await createReleaseNote(releaseNote)
        .then((res) => {
          if (res.status === 200) {
            setIsSavingReleaseNote(false);
            Notify.success(`Release note has been saved`);
            navigate(routeNames.release_notes);
          }
        })
        .catch((err) => {
          if (err.response) {
            setIsSavingReleaseNote(false);

            Message(
              err.response.data.error.message,
              err.response.data.error.details,
              "error"
            );
          }
        });
    }
  };

  return (
    <>
      <PageTitle title={id ? "Edit release note" : "Add release note"} />

      <Formik
        enableReinitialize={true}
        initialValues={{
          content: releaseNote ? releaseNote.content : "",
          title: releaseNote ? releaseNote.title : "",
          id: releaseNote ? releaseNote.id : undefined,
          releaseDate: releaseNote
            ? releaseNote.releaseDate
            : moment(new Date()).format("YYYY-MM-DD"),
          versionNumber: releaseNote ? releaseNote.versionNumber : "",
        }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, values }) => (
          <Form onSubmit={handleSubmit} className="mt-2">
            <Row className="mb-4">
              <Col sm={12}>
                <AppDateInput
                  name="releaseDate"
                  label="Release date"
                  required
                />
              </Col>
            </Row>

            <Row className="mb-4">
              <Col sm={12}>
                <AppTextInput type="text" name="title" label="Title" required />
              </Col>
            </Row>

            <Row className="mb-4">
              <Col sm={12}>
                <AppTextInput
                  type="text"
                  name="versionNumber"
                  label="Version number"
                  required
                />
              </Col>
            </Row>

            <Row className="mb-4">
              <Col sm={12}>
                <WYSIWYGInput label="Content" name="content" required />
              </Col>
            </Row>

            <Row className="text-end">
              <Col>
                <Link to={routeNames.release_notes}>
                  <Button
                    className="me-3"
                    color="secondary"
                    size="lg"
                    type="button"
                  >
                    Cancel
                  </Button>
                </Link>
                <Button
                  color="primary"
                  size="lg"
                  type="submit"
                  disabled={isSavingReleaseNote}
                >
                  {values.id ? "Update release note" : "Create release note"}
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default CreateOrEditReleaseNote;
