import { Form, Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import * as Yup from "yup";
import { TenantContext } from "../../context/tenantContext";
import routeNames from "../../router/routeNames";
import { createOrUpdate, getProject } from "../../services/projectService";
import { getStayPleaseDatabases } from "../../services/stayPleaseService";
import { getRoles, getUsers } from "../../services/userService";
import {
  CreateOrUpdateProjectInput,
  DropdownItem,
  GetProjectOutput,
  TenantContextType,
} from "../../types";
import Message from "../../utility/notifications/Message";
import Notify from "../../utility/notifications/Notify";
import PageTitle from "../components/Common/PageTitle";
import AppCheckboxInput from "../components/forms/AppCheckboxInput";
import AppDateInput from "../components/forms/AppDateInput";
import AppSelectInput from "../components/forms/AppSelectInput";
import AppTextInput from "../components/forms/AppTextInput";
import AdditionalHoursTab from "./AdditionalHoursTab";
import ItemCodeTab from "./ItemCodeTab";
import Page from "../components/Page";

const validationSchema = Yup.object().shape({
  id: Yup.number(),
  city: Yup.string().required().label("City"),
  name: Yup.string().required().label("Name"),
  hfcId: Yup.number().required().label("HFC ID"),
  overviewType: Yup.string().required().label("Overviewtype"),
  needsPurchaseOrder: Yup.bool().required().label("Purchase order required"),
  staypleaseDatabaseName: Yup.string()
    .when("isStaypleaseTenant", {
      is: (isStaypleaseTenant) => isStaypleaseTenant,
      then: Yup.string().required(),
    })
    .label("Stayplease database name"),
});

const CreateOrEditProject = () => {
  const [loading, setLoading] = useState(false);
  const [isSavingProject, setIsSavingProject] = useState(false);
  const [project, setProject] = useState<GetProjectOutput>();
  const [users, setUsers] = useState<DropdownItem[]>();
  const [approverRoles, setApproverRoles] = useState<DropdownItem[]>();
  const [activeTab, setActiveTab] = useState("1");
  const [staypleaseDatabases, setStaypleaseDatabases] =
    useState<DropdownItem[]>();
  const { tenant: tenantContext } = useContext(
    TenantContext
  ) as TenantContextType;

  const initialValues: CreateOrUpdateProjectInput = {
    city: project ? project.city : "",
    hfcId: project ? project.hfcId : 0,
    name: project ? project.name : "",
    needsPurchaseOrder: project ? project.needsPurchaseOrder : false,
    overviewType: project ? project.overviewType : 0,
    staypleaseDatabaseName: project ? project.staypleaseDatabaseName : "",
    id: project ? project.id : undefined,
    userIds: project?.linkedUsers
      ? project.linkedUsers.map((a) => a.userId)
      : [],
    approverIds: project?.linkedApprovers
      ? project.linkedApprovers.map((a) => a.roleId)
      : [],
    isStaypleaseTenant: tenantContext?.isStayplease,
    hasRoomChecks: project?.hasRoomChecks || false,
    roomCheckStartDate: new Date(),
    numberOfRoomChecks: project?.numberOfRoomChecks,
  };

  const { id } = useParams();

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const navigate = useNavigate();

  const getStaypleaseDatabases = async () => {
    const result = await getStayPleaseDatabases();

    const convertedData = result.data.result.map(
      (item) =>
        ({
          value: item,
          label: item,
        } as DropdownItem)
    );

    setStaypleaseDatabases(convertedData);
  };

  useEffect(() => {
    async function fetchProject(id) {
      setLoading(true);
      const result = await getProject(id);

      if (result) {
        setProject(result.data.result);
      }
      setLoading(false);
    }

    async function fetchUsers() {
      const result = await getUsers();

      const convertedData = result.data.result.items.map(
        (user) =>
          ({
            value: user.id,
            label: user.fullName,
          } as DropdownItem)
      );

      setUsers(convertedData);
    }

    async function fetchRoles() {
      const result = await getRoles();

      const convertedData = result.data.result.items.map(
        (role) =>
          ({
            value: role.id,
            label: role.displayName,
          } as DropdownItem)
      );

      setApproverRoles(convertedData);
    }

    if (id) {
      fetchProject(id);
    }

    if (tenantContext?.isStayplease) {
      getStaypleaseDatabases();
    }

    fetchUsers();
    fetchRoles();
  }, [id, tenantContext]);

  const handleSubmit = async (project) => {
    setIsSavingProject(true);

    if (project.id) {
      await createOrUpdate(project)
        .then((res) => {
          if (res.status === 200) {
            setIsSavingProject(false);
            Notify.success(`Project ${project.name} has been updated`);
            navigate(routeNames.projects);
          }
        })
        .catch((err) => {
          if (err.response) {
            setIsSavingProject(false);

            Message(
              err.response.data.error.message,
              err.response.data.error.details,
              "error"
            );
          }
        });
    } else {
      await createOrUpdate(project)
        .then((res) => {
          if (res.status === 200) {
            setIsSavingProject(false);
            Notify.success(`Project ${project.name} has been created`);
            navigate(routeNames.projects);
          }
        })
        .catch((err) => {
          if (err.response) {
            setIsSavingProject(false);

            Message(
              err.response.data.error.message,
              err.response.data.error.details,
              "error"
            );
          }
        });
    }
  };

  return (
    <Page loading={loading} loadingMessage="Loading project data">
      <PageTitle title={id ? "Update project" : "Configure project"} />

      <Nav tabs>
        <NavItem>
          <NavLink
            className={activeTab === "1" ? "active" : ""}
            onClick={() => {
              toggle("1");
            }}
          >
            Common
          </NavLink>
        </NavItem>

        {project && (
          <>
            {!tenantContext?.isStayplease && (
              <NavItem>
                <NavLink
                  className={activeTab === "2" ? "active" : ""}
                  onClick={() => {
                    toggle("2");
                  }}
                >
                  Article configuration
                </NavLink>
              </NavItem>
            )}
            <NavItem>
              <NavLink
                className={activeTab === "3" ? "active" : ""}
                onClick={() => {
                  toggle("3");
                }}
              >
                Additional request configuration
              </NavLink>
            </NavItem>
          </>
        )}
      </Nav>

      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <>
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
            >
              {({ handleSubmit, values }) => (
                <Form onSubmit={handleSubmit} className="mt-2">
                  <h4>Project settings</h4>

                  <Row className="mb-4">
                    <Col lg={6} sm={12}>
                      <AppTextInput
                        type="number"
                        placeholder="HFC ID"
                        name="hfcId"
                        label="HFC id"
                        required
                      />
                    </Col>
                  </Row>

                  <Row className="mb-2">
                    <Col lg={6} sm={12}>
                      <div className="mb-3">
                        <AppTextInput
                          type="text"
                          placeholder="Project name"
                          name="name"
                          label="Project name"
                          required
                        />
                      </div>
                    </Col>
                    <Col lg={6} sm={12}>
                      <div className="mb-3">
                        <AppTextInput
                          type="text"
                          placeholder="City"
                          name="city"
                          label="City"
                          required
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <AppCheckboxInput
                        name="needsPurchaseOrder"
                        label="Purchase order required"
                      />
                    </Col>
                  </Row>

                  {users && users.length > 0 && (
                    <Row className="mb-2">
                      <Col lg={6}>
                        <AppSelectInput
                          isMultiple={true}
                          label="Select the users that need access to this project"
                          name="userIds"
                          options={users}
                          selectedOptions={values.userIds}
                        />
                      </Col>
                    </Row>
                  )}

                  {tenantContext?.isStayplease && (
                    <>
                      <Row className="mb-2">
                        <Col lg={6}>
                          <AppSelectInput
                            label="Select the stayplease database to retrieve the data from"
                            name="staypleaseDatabaseName"
                            options={staypleaseDatabases}
                          />
                        </Col>
                      </Row>

                      <Row className="mb-2">
                        <Col lg={12}>
                          <AppCheckboxInput
                            label="Project has room checks"
                            name="hasRoomChecks"
                          />
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        <Col lg={6}>
                          <AppTextInput
                            type="number"
                            label="Amount of roomchecks"
                            name="numberOfRoomChecks"
                            min="0"
                          />
                          <small className="form-text text-muted">
                            If you leave this field empty, all checkout rooms
                            will be marked as checkout checks
                          </small>
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        <Col lg={6}>
                          <AppDateInput
                            label="Room check start date"
                            name="roomCheckStartDate"
                          />
                        </Col>
                      </Row>
                    </>
                  )}

                  {approverRoles &&
                    approverRoles.length > 0 &&
                    !tenantContext?.isStayplease && (
                      <Row className="mb-2">
                        <Col lg={6}>
                          <AppSelectInput
                            isMultiple={true}
                            label="Select the roles that need to approve the data"
                            name="approverIds"
                            options={approverRoles}
                            selectedOptions={values.approverIds}
                          />
                        </Col>
                      </Row>
                    )}

                  <Row className="text-end">
                    <Col>
                      <Link to={routeNames.projects}>
                        <Button
                          className="me-3"
                          color="secondary"
                          size="lg"
                          type="button"
                        >
                          Cancel
                        </Button>
                      </Link>
                      <Button
                        color="primary"
                        size="lg"
                        type="submit"
                        disabled={isSavingProject}
                      >
                        {values.id ? "Update project" : "Create project"}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>

            <Row className="mt-3">
              <DataTable
                responsive
                columns={[
                  {
                    name: "Room checks",
                    selector: (row) => row.numberOfRoomChecks,
                    sortable: false,
                  },
                  {
                    name: "startDate",
                    selector: "startDate",
                    sortable: false,
                    format: (row) => {
                      const startDate = new Date(row.startDate);

                      if (row.startDate == "0001-01-01T00:00:00") return "-";

                      return startDate.toLocaleDateString(); // Format the date as desired
                    },
                  },
                ]}
                data={project?.roomChecks}
              />
            </Row>
          </>
        </TabPane>

        {project && (
          <>
            {!tenantContext?.isStayplease && (
              <>
                <TabPane tabId="2">
                  <ItemCodeTab projectId={id} />
                </TabPane>
              </>
            )}
            <TabPane tabId="3">
              <AdditionalHoursTab projectId={parseInt(id!)} />
            </TabPane>
          </>
        )}
      </TabContent>
    </Page>
  );
};

export default CreateOrEditProject;
