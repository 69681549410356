import React from "react";
import { useFormikContext } from "formik";
import { Input } from "reactstrap";
import AppFormError from "./AppFormError";
import AppFormLabel from "./AppFormLabel";
import * as _ from "lodash";

interface AppTextInputProps {
  name: string;
  label?: string;
  required?: boolean;
  [x: string]: any;
}

function AppTextInput({
  name,
  label,
  required = false,
  ...otherProps
}: AppTextInputProps) {
  const { setFieldTouched, setFieldValue, values, errors, touched } =
    useFormikContext();

  return (
    <>
      {values && (
        <>
          <AppFormLabel for="floatingInput" label={label} required={required} />

          <Input
            value={Object.byString(values, name)}
            onBlur={() => setFieldTouched(name)}
            onChange={(event) => setFieldValue(name, event.target.value)}
            {...otherProps}
          />
        </>
      )}
      {!values && (
        <>
          <AppFormLabel for="floatingInput" label={label} required={required} />

          <Input
            onBlur={() => setFieldTouched(name)}
            onChange={(event) => setFieldValue(name, event.target.value)}
            {...otherProps}
          />
        </>
      )}
      <AppFormError
        visible={_.get(touched, name)}
        error={_.get(errors, name)}
      ></AppFormError>
    </>
  );
}

export default AppTextInput;
