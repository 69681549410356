import React, { useContext, useEffect } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import UserContext from "../../../context/userContext";
import routeNames from "../../../router/routeNames";
import useJwt from "../../../services/useJwt";

const events = [
  "load",
  "mousemove",
  "mousedown",
  "click",
  "scroll",
  "keypress",
];

const ProtectedRoute = () => {
  const userContext = useContext(UserContext);

  const navigate = useNavigate();

  useEffect(() => {
    const userData = useJwt.getUser();
    if (userData) {
      var userActivityDate = localStorage.getItem("lastactivitytime");
      if (userActivityDate) {
        var inactiveSeconds =
          (new Date().getTime() - parseFloat(userActivityDate)) / 1000;

        if (inactiveSeconds > 3600) {
          localStorage.removeItem("lastactivitytime");
          useJwt.logout();

          userContext.onLoggedOut();

          navigate(routeNames.login);
        }
      }
    }
  }, []);

  let timer;
  let activityTimer;

  const handleActivityTimer = () => {
    activityTimer = setTimeout(() => {
      localStorage.setItem("lastactivitytime", new Date().getTime());
      resetActivityTimer();
    }, 10000); // 10 sec
  };

  // this function sets the timer that logs out the user after 10 secs
  const handleLogoutTimer = () => {
    timer = setTimeout(() => {
      // clears any pending timer.
      resetTimer();
      // Listener clean up. Removes the existing event listener from the window
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, resetTimer);
      });

      useJwt.logout();
      userContext.onLoggedOut();

      navigate(routeNames.login);

      resetTimer();
    }, 3600000); // 1 hour
  };

  // this resets the timer if it exists.
  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };

  const resetActivityTimer = () => {
    if (activityTimer) clearTimeout(activityTimer);
  };

  useEffect(() => {
    Object.values(events).forEach((item) => {
      window.addEventListener(item, () => {
        resetTimer();
        handleLogoutTimer();
      });
    });
  }, []);

  handleActivityTimer();

  const location = useLocation();

  const user = useJwt.getUser();

  if (!user) {
    return <Navigate to={routeNames.login} state={{ from: location }} />;
  }

  return (
    <>
      <div className="container mt-3 mb-5">
        <Outlet />
      </div>
    </>
  );
};

export default ProtectedRoute;
