// FAQSection.js
import React from "react";
import "./FAQSection.css";
import FAQItem from "./FaqItem";

function FAQSection({ faqData }) {
  return (
    <div className="faq-section">
      {faqData.map((faqSection, index) => (
        <>
        <h4>{faqSection.section}</h4>
        {faqSection.item.map((faqItem, indexItem) => (
 <FAQItem
 key={index}
 question={faqItem.question}
 answer={faqItem.answer}
/>
        ))}   
        </>
      ))}
    </div>
  );
}

export default FAQSection;
