import { AxiosResponse } from "axios";
import {
  ReleaseNoteEditDto,
  ReleaseNoteListDtoPagedResultDto,
} from "./../types/index";
import http from "./httpService";

const apiEndpoint = "/services/app/ReleaseNote";

export function getReleaseNotesForClient(): Promise<
  AxiosResponse<ReleaseNoteListDtoPagedResultDto>
> {
  return http.get(apiEndpoint + "/getReleaseNotesForClient?maxResultCount=500");
}

export function markAsRead() {
  return http.post(apiEndpoint + "/MarkAsRead");
}
export function getReleaseNotes(): Promise<
  AxiosResponse<ReleaseNoteListDtoPagedResultDto>
> {
  return http.get(apiEndpoint + "/GetAll?maxResultCount=500");
}

export function createReleaseNote(releaseNote: ReleaseNoteEditDto["result"]) {
  return http.post(apiEndpoint + "/CreateOrUpdate", releaseNote);
}

export function updateReleaseNote(releaseNote: ReleaseNoteEditDto["result"]) {
  return http.post(apiEndpoint + "/CreateOrUpdate", releaseNote);
}

export function deleteReleaseNote(id: number) {
  return http.delete(apiEndpoint + `/delete?id=${id}`);
}

export function getReleaseNote(
  id: number
): Promise<AxiosResponse<ReleaseNoteEditDto>> {
  return http.get(apiEndpoint + `/getById?id=${id}`);
}
export function getLastReleaseNote(): Promise<
  AxiosResponse<ReleaseNoteEditDto>
> {
  return http.get(apiEndpoint + `/getLastReleaseNote`);
}
