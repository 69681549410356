import React from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import moment from "moment";

interface MessageTimeLineItemProps {
  userName?: string;
  value: string;
  creationTime?: Date;
}

const MessageTimeLineItem = ({
  userName,
  value,
  creationTime,
}: MessageTimeLineItemProps) => {
  return (
    <>
      <div className="d-flex mb-3">
        <Card className="w-100">
          <CardHeader>
            {userName ? (
              <>
                <strong>{userName}</strong> added comment on{" "}
                <strong>
                  {moment(creationTime).format("DD-MM-YYYY HH:mm")}
                </strong>
              </>
            ) : (
              <strong>Comment</strong>
            )}
          </CardHeader>
          <CardBody>{value}</CardBody>
        </Card>
      </div>
    </>
  );
};

export default MessageTimeLineItem;
