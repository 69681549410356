// FAQItem.js
import React, { useState } from "react";

function FAQItem({ question, answer }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="faq-item">
      <div
        className={`faq-question ${isOpen ? "open" : ""}`}
        onClick={toggleAccordion}
      >
        {question}
        <span className="toggle-icon">{isOpen ? "-" : "+"}</span>
      </div>
      {isOpen && <div className="faq-answer" dangerouslySetInnerHTML={{ __html: answer }}
 />}
    </div>
  );
}

export default FAQItem;
