import React from "react";
import DataTable from "react-data-table-component";
import { ProjectListItem } from "../../../types";
import AppButton from "./AppButton";

interface ProjectCardProps {
  data: ProjectListItem[];

  onButtonClick: (id: number) => void;
}

const ProjectTable = ({ onButtonClick, data }: ProjectCardProps) => {
  return (
    <DataTable
      pagination
      responsive
      columns={[
        {
          name: "name",
          selector: (row) => row.name,
          sortable: true,
        },
        {
          name: "ProjectId",
          selector: (row) => row.hfcId,
          sortable: true,
        },
        {
          name: "Actions",
          cell: (row) => (
            <>
              <AppButton
                buttonSize="sm"
                onClick={() => onButtonClick(row.id)}
                variant="search"
                title="Open daily"
              />
            </>
          ),
        },
      ]}
      data={data}
    />
  );
};

export default ProjectTable;
