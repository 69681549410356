import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getReleaseNote } from "../../services/releaseNoteService";
import { ReleaseNoteEditDto } from "../../types";
import ReleaseNoteDetailsComponent from "../components/releaseNotes/ReleaseNoteDetails";
import PageTitle from "../components/Common/PageTitle";

const ReleaseNoteDetails = () => {
  const [releaseNote, setReleaseNote] = useState<ReleaseNoteEditDto>();

  const { id } = useParams();

  useEffect(() => {
    async function fetchReleaseNote() {
      if (id) {
        const result = await getReleaseNote(parseInt(id || "0"));

        if (result) {
          setReleaseNote(result.data);
        }
      }
    }

    fetchReleaseNote();
  }, [id]);

  return (
    <>
      <PageTitle title="Release notes" />

      {releaseNote?.result && (
        <ReleaseNoteDetailsComponent releaseNote={releaseNote?.result} />
      )}
    </>
  );
};

export default ReleaseNoteDetails;
