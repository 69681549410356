import React from "react";
import { ReleaseNoteEditDto } from "../../../types";
import moment from "moment";
import { Row, Col } from "react-bootstrap";

interface ReleaseNoteDetailsComponentProps {
  releaseNote: ReleaseNoteEditDto["result"];
}

const ReleaseNoteDetailsComponent = ({
  releaseNote,
}: ReleaseNoteDetailsComponentProps) => {
  return (
    <>
      <Row className="mb-4">
        <Col sm={12}>
          <h4>
            Release {releaseNote?.versionNumber} - {releaseNote?.title}
          </h4>
          <span>
            Date:{" "}
            {moment(releaseNote?.releaseDate).format(
              "dddd | DD-MM-yyyy | hh:mm:ss"
            )}
          </span>
        </Col>
      </Row>

      <h5>Details</h5>
      <div
        style={{ margin: 0 }}
        dangerouslySetInnerHTML={{ __html: releaseNote?.content || "" }}
      />
    </>
  );
};

export default ReleaseNoteDetailsComponent;
