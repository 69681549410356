import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useEffect, useState } from "react";
import { Button, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { getItemConfiguration } from "../../services/stayPleaseService";
import {
  StaypleaseItemConfigurationListItem,
  UpdateStayPleaseItemInput,
} from "../../types";
import PageTitle from "../components/Common/PageTitle";
import EditItemConfiguration from "./Edit";

const ItemConfiguration = () => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [itemToEdit, setItemToEdit] =
    useState<StaypleaseItemConfigurationListItem>();
  const [items, setItems] = useState<StaypleaseItemConfigurationListItem[]>();

  useEffect(() => {
    async function fetchItems() {
      const response = await getItemConfiguration();
      setItems(response.data.result.items);
    }
    fetchItems();
  }, []);

  const handleUpdated = (input: UpdateStayPleaseItemInput) => {
    const updatedItems = items?.map((item) => {
      if (item.id === input.id) {
        return { ...item, code: input.code };
      }
      return item;
    });

    setItems(updatedItems);
  };

  return (
    <>
      <PageTitle title="Stayplease item configuration" />

      {!items && (
        <Row className="text-center">
          <h5>Geen mapping gevonden</h5>
        </Row>
      )}

      {items && items.length > 0 && (
        <Row>
          <DataTable
            pagination
            responsive
            defaultSortAsc={true}
            defaultSortFieldId="name"
            columns={[
              {
                name: "code",
                selector: (row: StaypleaseItemConfigurationListItem) =>
                  row.code,
                sortable: true,
              },
              {
                id: "name",
                name: "Name",
                selector: (row: StaypleaseItemConfigurationListItem) =>
                  row.name || "",
                sortable: true,
              },
              {
                name: "Type",
                selector: (row: StaypleaseItemConfigurationListItem) => {
                  if (row.type === 0) return "End of day";
                  else if (row.type === 1) return "Start of day";
                  else if (row.type === 2) return "Extra rooms";
                  else return "-";
                },
                sortable: true,
              },
              {
                name: "Actions",
                cell: (row: StaypleaseItemConfigurationListItem) => (
                  <Button
                    onClick={() => {
                      setShowEditModal(true);
                      setItemToEdit(row);
                    }}
                    size="sm"
                    color="danger"
                  >
                    <FontAwesomeIcon icon={faPencilAlt as any} size="xs" />
                  </Button>
                ),
              },
            ]}
            data={items}
          />
        </Row>
      )}
      <EditItemConfiguration
        handleHide={() => setShowEditModal(false)}
        show={showEditModal}
        configItem={itemToEdit}
        handleUpdated={handleUpdated}
      />
    </>
  );
};

export default ItemConfiguration;
