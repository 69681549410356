import {
  isAreaManagerUser,
  isCustomerAdminUser,
  isHFCAreaManagerUser,
  isHostUser,
  isHouseKeeperUser,
  isMODUser,
  isOrganisationAdminUser,
  isViewerUser,
} from "../utility/Utils";
import routeNames from "./routeNames";

export const handleLoggedInRoute = (): string => {
  if (isHouseKeeperUser()) {
    return routeNames.daily;
  }

  if (isAreaManagerUser()) {
    return routeNames.additional_hours_overview + "1";
  }

  if (isHFCAreaManagerUser()) {
    return routeNames.additional_hours_overview + "0";
  }

  if (isMODUser()) {
    return routeNames.release_notes;
  }

  if (isOrganisationAdminUser()) {
    return routeNames.users;
  }

  if (isHostUser()) {
    return routeNames.hostDashboard;
  }

  if (isCustomerAdminUser()) {
    return routeNames.users;
  }

  if (isViewerUser()) {
    return routeNames.area_manager_dashboard;
  }

  return routeNames.daily;
};
