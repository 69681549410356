import { useEffect, useState } from "react";
import { getDeclinedDailys } from "../../services/dailyService";
import * as moment from "moment";

const DeclinedDailyComponent = ({ handleClick, buttonText, projectId }) => {
  const [declinedDailys, setDeclinedDailys] = useState();

  useEffect(() => {
    async function fetchDeclinedDailys(projectId) {
      const declinedDailys = await getDeclinedDailys(projectId);
      setDeclinedDailys(declinedDailys.data.result);
    }

    fetchDeclinedDailys(projectId);
  }, []);

  return (
    <div>
      {declinedDailys &&
        declinedDailys.map((daily, i) => {
          return (
            <div
              className="d-flex align-items-center p-3 my-3 text-white bg-danger rounded shadow-sm"
              key={`dailyissue-${i}`}
            >
              <div className="lh-1">
                <h1 className="h6 mb-0 text-white lh-1">
                  <span>{daily.projectName} - </span>
                  {moment(daily.startDate).format("dddd | DD-MM-yyyy | ")}
                  week {moment(daily.startDate).isoWeek()}
                  <span> / declined</span>
                  <button
                    type="button"
                    className="ms-3 btn btn-secondary btn-sm text-white"
                    onClick={() => handleClick(daily)}
                  >
                    {buttonText}
                  </button>
                </h1>
                <small>{daily.comment}</small>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default DeclinedDailyComponent;
