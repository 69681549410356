import { useState } from "react";
import ResetPasswordForm from "../../modules/authentication/ResetPasswordForm";
import { Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
import React from "react";
import routeNames from "../../router/routeNames";

const ActivateAccount = () => {
  const [activatingAccountDone, setActivatingAccountDone] = useState(false);

  return (
    <>
      {!activatingAccountDone ? (
        <>
          <h1 className="h3 mb-3 fw-bold text-primary">Activate account</h1>

          <p>Create a password below to finish your account creation.</p>

          <ResetPasswordForm
            submitButtonLabel="Activate account"
            onResettingPasswordDone={() => setActivatingAccountDone(true)}
          />
        </>
      ) : (
        <>
          <h1 className="h3 mb-3 fw-bold text-primary">Account activated</h1>

          <p>
            Your account has been activated, click the link below to go back to
            login.
          </p>

          <Row className="row mt-2">
            <Col className="d-flex justify-content-center">
              <Link to={routeNames.login}>Back to login</Link>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default ActivateAccount;
