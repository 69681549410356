const ApplicationSides = {
  TENANTSIDE: 1,
  HOSTSIDE: 2,
  BOTHSIDES: 3,
};

const Menu_item_types = {
  DIVIDER: 1,
  HEADER: 2,
};

const Daily_status_types = {
  New: 1,
  IsDeclinedByMOD: 5,
  SendForReview: 10,
  IsReadyForApprovalByAreaManager: 14,
  DeclinedByAreaManager: 15,
  ApprovedByAreaManager: 20,
};

const Additionalwork_status_types = {
  New: 1,
  SendForReview: 10,
  DeclinedByAreaManager: 15,
  ApprovedByAreaManager: 20,
  DeclinedByHotelManager: 25,
  ApprovedByHotelManager: 30,
};

const Stayplease_daily_status_types = {
  SendForReview: 0,
  IsDeclined: 1,
  IsApprovedByMOD: 2,
  IsApprovedByHotelManager: 3,
  IsApprovedByHFCAreaManager: 4,
};

const CitizenM_approvers = {
  HotelManager: 1,
  HFCAreaManager: 2,
};

export const ProjectOverviewTypesDropdown = [
  { value: 0, label: "Weekly" },
  { value: 1, label: "Monthly" },
];

const Project_overview_types = {
  Weekly: 0,
  Monthly: 1,
};

const Weekly_Daily_Item_Status = {
  Approved: 0,
  Declined: 1,
  Outstanding: 2,
};

const Additional_Request_Approver = {
  AreaManager: 0,
  HotelManager: 1,
};

export {
  ApplicationSides,
  Menu_item_types,
  Daily_status_types,
  Stayplease_daily_status_types,
  CitizenM_approvers,
  Project_overview_types,
  Weekly_Daily_Item_Status,
  Additionalwork_status_types,
  Additional_Request_Approver,
};

export const itemCodeApplicableDays = [
  { value: 1, label: "Monday" },
  { value: 2, label: "Tuesday" },
  { value: 3, label: "Wednesday" },
  { value: 4, label: "Thursday" },
  { value: 5, label: "Friday" },
  { value: 6, label: "Saturday" },
  { value: 7, label: "Sunday" },
];

export const TenantSignOffFlow = {
  Weekly: 1,
  Daily: 2,
};

export const TenantSignOffFlowDropdown = [
  { value: 1, label: "Weekly" },
  { value: 2, label: "Daily" },
];

export const PasswordRequirements = {
  expression:
    /^(?=.*[a-zA-Z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{8,}$/,
  label:
    "The password must contain at least 8 characters, including 1 uppercase letter, 1 lowercase letter, and 1 special character.",
};
