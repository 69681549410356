import React, { useContext } from "react";
import { SwitchUserInput } from "../../../types";
import { SwitchUser } from "../../../services/accountService";
import UserContext from "../../../context/userContext";
import { useNavigate } from "react-router-dom";
import routeNames from "../../../router/routeNames";
import { handleLoggedInRoute } from "../../../router/routeHelper";

interface CardProps {
  data: {
    tenantId: number | null;
    userId: number;
    tenantName: string | null;
  };
}

const SwitchCard: React.FC<CardProps> = ({ data }) => {
  const userContext = useContext(UserContext);
  const navigate = useNavigate();

  const handleLoginClick = async (userId: number, tenantId?: number | null) => {
    await LoginToUser(userId, tenantId);
  };

  const LoginToUser = async (userId, tenantId) => {
    const input: SwitchUserInput = {
      targetUserId: userId,
      targetTenantId: tenantId,
    };

    await SwitchUser(input).then((res) => {
      if (res.status === 200) {
        const data = {
          accessToken: res.data.result.accessToken,
          ...res.data.result.user,
          isHost: tenantId ? false : true,
          refreshToken: res.data.result.refreshToken,
          isImpersonated: false,
        };

        userContext.onLoggedIn(data);

        navigate(handleLoggedInRoute());
      }
    });
  };

  return (
    <div
      style={{
        border: "1px solid #ccc",
        borderRadius: "5px",
        padding: "10px",
        marginBottom: "3px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div>
          <strong>Tenant Name:</strong> {data.tenantName || "Admin environment"}
        </div>
        <button
          className="btn btn-primary"
          onClick={() => handleLoginClick(data.userId, data.tenantId)}
          style={{ padding: "10px 20px", cursor: "pointer" }}
        >
          Login
        </button>
      </div>
    </div>
  );
};

export default SwitchCard;
