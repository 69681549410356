import { useContext } from "react";
import { useParams } from "react-router-dom";
import { TenantContext } from "../../context/tenantContext";
import StayPleaseDaily from "./StayPleaseDaily";
import RegularDaily from "./RegularDaily";
import { TenantContextType } from "../../types";

const DailySheet = () => {
  const { tenant: tenantContext } = useContext(
    TenantContext
  ) as TenantContextType;

  const { id } = useParams();

  return (
    <>
      {tenantContext?.isStayplease ? (
        <StayPleaseDaily id={parseInt(id || "")} />
      ) : (
        <RegularDaily id={parseInt(id || "")} />
      )}
    </>
  );
};

export default DailySheet;
