import React from "react";
import { Button, Card } from "react-bootstrap";
import { CardBody, CardHeader } from "reactstrap";

interface ProjectCardProps {
  name: string;
  buttonText: string;
  city: string;
  hfcId: number;
  isConfigurationNotValid?: boolean;
  onButtonClick: () => void;
}

const ProjectCard = ({
  onButtonClick,
  isConfigurationNotValid,
  hfcId,
  city,
  buttonText,
  name,
}: ProjectCardProps) => {
  return (
    <Card>
      <CardHeader className="py-3 text-center">
        <h4 className="my-0 fw-normal">{name}</h4>
      </CardHeader>
      <CardBody>
        <ul className="list-unstyled ">
          <li className="fw-bold">City: {city}</li>
          <li className="fw-bold">HFC code: {hfcId}</li>
        </ul>
        {isConfigurationNotValid ? (
          <p>Invalid project configuration, please contact administrator</p>
        ) : (
          <Button
            color="secondary"
            className="w-100 btn btn-lg"
            onClick={onButtonClick}
          >
            {buttonText}
          </Button>
        )}
      </CardBody>
    </Card>
  );
};

export default ProjectCard;
