import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TenantContext } from "../../context/tenantContext";
import routeNames from "../../router/routeNames";
import { getMyProjects } from "../../services/projectService";
import { ProjectListItem, TenantContextType } from "../../types";

import ProjectSelectionPage from "../components/pages/projectSelectionPage";

const DailyStartScreen = () => {
  const navigate = useNavigate();
  const [projects, setProjects] = useState<ProjectListItem[]>();
  const [searchTerm, setSearchTerm] = useState<string>("");

  useEffect(() => {
    async function fetchUserProjects() {
      const projects = await getMyProjects(searchTerm);

      const projectList: ProjectListItem[] = projects.data.result;
      if (projectList && projectList.length === 1) {
        navigate(`${routeNames.daily}/${projectList[0].id}`);
      } else {
        setProjects(projectList);
      }
    }

    fetchUserProjects();
  }, [navigate, searchTerm]);

  return (
    <ProjectSelectionPage
      projects={projects}
      buttonText='Open the daily sheet'
      onHandleSearch={(val) => {
        setSearchTerm(val);
      }}
      pageTitle="Project overview"
      onProjectSelection={(id) => navigate(`${routeNames.daily}/${id}`)}
    />
  );
};

export default DailyStartScreen;
