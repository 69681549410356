import React, { useContext, useState } from "react";

import { Container } from "react-bootstrap";
import { To, useNavigate } from "react-router-dom";
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

import { ReactComponent as Logo } from "../../../assets/media/logo.svg";
import UserContext from "../../../context/userContext";
import { isInRole, isLoggedInInSide } from "../../../utility/Utils";

import {
  faBars,
  faQuestion,
  faRightLeft,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TenantContext } from "../../../context/tenantContext";
import NavigationItems from "../../../navigation/index";
import routeNames from "../../../router/routeNames";
import { TenantContextType } from "../../../types";
import { Menu_item_types } from "../../../utility/constants";
import AppButton from "./AppButton";

const Navbar = () => {
  const { currentUser } = useContext(UserContext);
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { tenant } = useContext(TenantContext) as TenantContextType;

  return (
    <header className="p-1 bg-primary text-white">
      <Container>
        <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
          <ul className="nav col-12 col-lg-auto mb-2 justify-content-center mb-md-0">
            <ButtonDropdown
              isOpen={dropdownOpen}
              toggle={() => setDropdownOpen(!dropdownOpen)}
            >
              <DropdownToggle
                color={dropdownOpen ? "secondary" : ""}
                className={dropdownOpen ? "text-primary" : "text-white"}
              >
                <FontAwesomeIcon icon={faBars} size="lg" />
              </DropdownToggle>
              <DropdownMenu className="bg-primary border-primary">
                {currentUser &&
                  NavigationItems &&
                  NavigationItems.map((item, i) => {
                    {
                      if (
                        item.requiresAdditionalItems &&
                        (tenant?.hasProjectsWithAdditionalItems == false ||
                          tenant?.hasProjectsWithAdditionalItems == null)
                      ) {
                        return;
                      }

                      if (
                        item.type === Menu_item_types.DIVIDER &&
                        isLoggedInInSide(item.side) &&
                        isInRole(item.requiredRole)
                      ) {
                        return <DropdownItem divider key={`dropdown${i}`} />;
                      } else if (
                        item.type === Menu_item_types.HEADER &&
                        isLoggedInInSide(item.side) &&
                        isInRole(item.requiredRole)
                      ) {
                        return (
                          <DropdownItem
                            key={`dropdown${i}`}
                            className="text-white bg-transparent"
                            header
                          >
                            {item.title}
                          </DropdownItem>
                        );
                      }

                      if (
                        item.requiredRole &&
                        isInRole(item.requiredRole) &&
                        isLoggedInInSide(item.side) &&
                        ((!item.isStayPlease && !tenant?.isStayplease) ||
                          item.isStayPlease == tenant?.isStayplease ||
                          item.isStayPlease === undefined) &&
                        (tenant?.flowType === item.requiredTenantflow ||
                          item.requiredTenantflow == null)
                      ) {
                        return (
                          <DropdownItem
                            className="text-white custom-menu-dropdown"
                            key={item.id}
                            onClick={() => navigate(item.navLink as To)}
                          >
                            {item.title}
                          </DropdownItem>
                        );
                      } else if (
                        isLoggedInInSide(item.side) &&
                        !item.requiredRole
                      ) {
                        return (
                          <DropdownItem
                            className="text-white custom-menu-dropdown"
                            key={item.id}
                            onClick={() => navigate(item.navLink as To)}
                          >
                            {item.title}
                          </DropdownItem>
                        );
                      }
                    }
                  })}
              </DropdownMenu>
            </ButtonDropdown>
          </ul>

          <a className="d-flex align-items-center mb-2 mb-lg-0 text-white text-decoration-none d-none d-sm-block">
            <Logo className="img-fluid bg-white" />
          </a>

          <ul className="nav col-lg-auto me-lg-5 mb-2 justify-content-center mb-md-0">
            <li className="d-lg-flex">
              <a href="#" className="nav-link px-2 text-white header-title">
                Daily report
              </a>
            </li>
          </ul>

          <ul className="nav col-12 col-lg-auto me-lg-auto mb-2"></ul>

          <div className="text-end mb-xs-2">
            <button
              type="button"
              className="btn btn-outline-light me-2"
              onClick={() => navigate(routeNames.logout)}
            >
              Logout, {tenant?.name && `${tenant?.name}/`}
              {currentUser.fullName}
            </button>

            {currentUser.isImpersonated && (
              <AppButton
                type="button"
                variant="impersonate"
                size="m"
                className="btn btn-outline-light me-2"
                onClick={() => navigate(routeNames.to_impersonator)}
                title={"Back to host user"}
              >
                Back to host user
              </AppButton>
            )}

            <button
              type="button"
              className="btn btn-xs btn-success"
              onClick={() => navigate(routeNames.profile)}
            >
              <FontAwesomeIcon icon={faUser} />
            </button>

            {tenant?.canSwitchTenant && (
              <button
                type="button"
                className="btn btn-xs btn-secondary ms-2"
                onClick={() => navigate(routeNames.switchUser)}
              >
                <FontAwesomeIcon icon={faRightLeft} />
              </button>
            )}

            {tenant?.isStayplease && (
              <button
                type="button"
                className="btn btn-xs btn-warning ms-2"
                onClick={() => navigate(routeNames.faq)}
              >
                <FontAwesomeIcon icon={faQuestion} />
              </button>
            )}
          </div>
        </div>
      </Container>
    </header>
  );
};

export default Navbar;
