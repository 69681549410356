import { Col, Form, Row } from "react-bootstrap";
import AppButton from "./AppButton";
import React, { useEffect } from "react";

import { useContext, useState } from "react";
import { Input } from "reactstrap";
import UserContext from "../../../context/userContext";
import { Formik } from "formik";
import { changePassword } from "../../../services/profileService";
import {
  getUsersForDropdown,
  setOutOfOffice,
} from "../../../services/userService";
import AppSelectInput from "../forms/AppSelectInput";
import AppDateInput from "../forms/AppDateInput";
import Message from "../../../utility/notifications/Message";
import * as Yup from "yup";

const today = new Date();

const validationSchema = Yup.object().shape({
  startDate: Yup.date()
    .min(today, 'Start date must be today or later')
    .required()
    .label("Date from"),
  endDate: Yup.date()
    .min(
      Yup.ref('startDate'),
      'End date must be after the start date'
    )
    .required()
    .label("Date till"),
});

const OutOfOfficeForm = ({}) => {
  const [users, setUsers] = useState([]);

  const handleSubmit = async (input, { resetForm }) => {
    await setOutOfOffice(input).then((res) => {
      Message(
        "Out of office updated",
        "Your out of office has been set",
        "success"
      );
    });
  };

  async function fetchUsers() {
    const users = await getUsersForDropdown();
    if (users) setUsers(users.data.result.items);
  }

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <Formik
      enableReinitialize={true}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      initialValues={{
        userIds: [],
        endDate: new Date(),
        startDate: new Date()
      }}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Form onSubmit={handleSubmit}>
          <>
            <Row>
              <Col lg={6} sm={12}>
                <div className="mb-3">
                  <AppSelectInput
                    label="Users"
                    name="userIds"
                    isMultiple
                    options={users}
                  />
                </div>
              </Col>

              <Col sm={3}>
                <AppDateInput required label="Start date" name={`startDate`} />
              </Col>

              <Col sm={3}>
                <AppDateInput required label="End date" name={`endDate`} />
              </Col>
              
         
            </Row>
            <Row>
              <p>
              Please select the user(s) that can approve on your behalf the administration during your out of office.  
Select the start and end date. Please note that the authorisations will be revoked on that day. Press Set out of office button 

              </p>
            </Row>
          </>

          <Row className="text-end">
            <Col>
              <AppButton
                title="Set out of office"
                buttonSize="lg"
                type="submit"
                loading={isSubmitting}
                disabled={isSubmitting}
              />
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default OutOfOfficeForm;
