import { Formik } from "formik";
import moment from "moment";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Col,
  Form,
  Row,
} from "reactstrap";
import { getProject } from "../../services/projectService";
import {
  getStayPleaseWeeklyDataForSignOff,
  processStayPleaseWeeklySignOff,
} from "../../services/stayPleaseService";
import { GetCitizenMWeeklySignOffData, ProjectListItem } from "../../types";
import {
  Project_overview_types,
  Stayplease_daily_status_types,
} from "../../utility/constants";
import HeaderBanner from "../components/banners/HeaderBanner";
import AppButton from "../components/Common/AppButton";
import DateToggler from "../components/Common/DateToggler";
import PageTitle from "../components/Common/PageTitle";
import { AppSignaturepad } from "../components/forms/AppSignaturepad";
import AppTextAreaInput from "../components/forms/AppTextAreaInput";
import AppTextInput from "../components/forms/AppTextInput";
import * as Yup from "yup";
import Message from "../../utility/notifications/Message";
import React from "react";
import MessageTimeLineItem from "../components/timeline/MessageTimeLineItem";
import { calculateNextPrevWeek } from "../../utility/DateFilters";
import DatePickerFilter from "../components/Common/DateFilterPickr";
import useWeekPart from "../../utility/hooks/useWeekPart";

//Voor de rol HFC area manager & hotel manager
const validationSchema = Yup.object().shape({
  comment: Yup.string().nullable().max(1000),
});

const CmApproveDataWeekly = () => {
  const [index, setIndex] = useState("");
  const [week, setWeek] = useState<number>();
  const [year, setYear] = useState<number>();
  const [weekPart, setWeekPart] = useState<number>();
  const [dataEntryComplete, setDataEntryComplete] = useState(false);

  const { role, id } = useParams();
  const [dateInfo, calculateWeekPart] = useWeekPart();

  const [weeklyData, setWeeklyData] = useState<GetCitizenMWeeklySignOffData>();
  const [fetchingData, setFetchingData] = useState(false);
  const [saving, setSaving] = useState(false);
  const [project, setProject] = useState<ProjectListItem>();

  useEffect(() => {
    getWeeklyData(parseInt(id!), moment().year(), moment().isoWeek(), 1);
    getProjectData(parseInt(id!));
  }, [id]);

  const getWeeklyData = async (
    projectId: number,
    year: number,
    week: number,
    weekPart: number
  ) => {
    setFetchingData(true);
    try {
      await getStayPleaseWeeklyDataForSignOff(
        projectId,
        year,
        week,
        weekPart
      ).then((result) => {
        if (result) {
          setWeeklyData(result.data.result);
        }
      });
    } catch (exception) {
      setWeeklyData(undefined);
    }

    setYear(year);
    setWeek(week);
    setWeekPart(weekPart);

    setFetchingData(false);
  };

  const getProjectData = async (id: number) => {
    await getProject(id).then((result) => {
      setProject(result.data.result);
    });
  };

  const handleSubmit = async (input: SubmitWeeklyApproval) => {
    if (!input.isApproved && (!input.comment || input.comment.trim().length < 5)) {
      return Message(
        "Missing comment",
        "You must add a comment when declining the daily",
        "warningNoBtns"
      );
    }

    setSaving(true);

    await processStayPleaseWeeklySignOff({
      comment: input.comment,
      date: weeklyData!.days[0].date,
      isApproved: input.isApproved,
      projectId: parseInt(id!),
      signature: input.signature,
      overviewType: Project_overview_types.Weekly,
    });

    if (input.isApproved) {
      let weeklyDataNew = { ...weeklyData! };
      weeklyDataNew.dailyStatus =
        Stayplease_daily_status_types.IsApprovedByHotelManager;
      setWeeklyData(weeklyDataNew);
    } else {
      let weeklyDataNew = { ...weeklyData! };
      weeklyDataNew.dailyStatus = Stayplease_daily_status_types.IsDeclined;
      setWeeklyData(weeklyDataNew);
    }

    setSaving(false);
  };

  const handleDatePickerChange = (newDate) => {

    calculateWeekPart(newDate);
  
    // Now call fetchData with the new year, week, weekPart, and the existing filters
    getWeeklyData(parseInt(id!),dateInfo.selectedYear, dateInfo.selectedWeek, dateInfo.weekPart);
  }; 

  const handleNextPrevDate = (isPrevious: boolean) => {
    const newData = calculateNextPrevWeek(
      isPrevious,
      week!,
      weekPart!,
      weeklyData?.hasSecondWeekPart!,
      year!
    );

    getWeeklyData(parseInt(id!), newData.year!, newData.week, newData.weekPart);
  };

  function toggle(newIndex) {
    if (newIndex === index) {
      setIndex("");
    } else {
      setIndex(newIndex);
    }
  }

  interface SubmitWeeklyApproval {
    comment: string;
    signature: string;
    isApproved: false;
  }

  const canProcessData = (): boolean => {
    if (
      !weeklyData ||
      weeklyData.dailyStatus ===
        Stayplease_daily_status_types.IsApprovedByHFCAreaManager
    )
      return false;

    const dailyStatus = weeklyData!.dailyStatus;
    if (
      role === "HotelManager" &&
      dailyStatus === Stayplease_daily_status_types.IsApprovedByMOD
    ) {
      return true;
    }
    return false;
  };

  return (
    <div>
      <PageTitle title={project?.name || ""} customStyles="mb-2" />

      <DateToggler
        title={
          weeklyData?.hasSecondWeekPart || weekPart === 2 ? (
            <span className="mx-3">
              week {week}.{weekPart}
            </span>
          ) : (
            <span className="mx-3">week {week}</span>
          )
        }
        onNextDate={() => handleNextPrevDate(false)}
        handleDatePickerChange={(date) => handleDatePickerChange(date)}
        onPreviousDate={() => handleNextPrevDate(true)}
      />
      
      {fetchingData && (
        <Row className="text-center">
          <h4>Loading...</h4>
        </Row>
      )}

      {!fetchingData && !weeklyData && (
        <Row className="text-center">
          <h4>Administration has not yet been submitted...</h4>
        </Row>
      )}

      {weeklyData && (
        <>
          {weeklyData.dailyStatus ===
            Stayplease_daily_status_types.IsDeclined && (
            <HeaderBanner
              title="Daily declined"
              message="This daily has been sent back to the housekeeper because it is declined"
              type="danger"
            />
          )}

          {!weeklyData.isReadyForSignOff && (
            <HeaderBanner
              title="Overview cannot be processed yet"
              message="This overview cannot be processed yet because one or multiple daylies haven't been submitted yet."
              type="info"
            />
          )}

          {role === "HotelManager" &&
            weeklyData.dailyStatus ===
              Stayplease_daily_status_types.IsApprovedByHotelManager && (
              <HeaderBanner
                title="The weekly overview has been approved"
                type="info"
              />
            )}

          {role === "HFCAreaManager" &&
            weeklyData.dailyStatus ===
              Stayplease_daily_status_types.IsApprovedByHFCAreaManager && (
              <HeaderBanner
                title="The weekly overview has been approved"
                type="info"
              />
            )}
          {weeklyData.totals && (
            <Accordion open={index} toggle={toggle} key={999}>
              <AccordionItem className="mb-3">
                <AccordionHeader targetId={`accordionItem999`}>
                  Totals
                </AccordionHeader>
                <AccordionBody accordionId={`accordionItem999`}>
                  <>
                    <Row>
                      <Col lg={8} sm={8}>
                        <h6>Category</h6>
                      </Col>
                      <Col lg={4} sm={4}>
                        <h6>Amount</h6>
                      </Col>
                    </Row>

                    {weeklyData.totals.map((item, x) => {
                      return (
                        <div>
                          <h6 className="mt-3">{item.name}</h6>
                          {item.items.map((total, i) => {
                            return (
                              <Row className="mb-1" key={i}>
                                <Col lg={8} sm={8}>
                                  {total.name}
                                </Col>
                                <Col lg={4} sm={4}>
                                  {total.value}
                                </Col>
                              </Row>
                            );
                          })}
                        </div>
                      );
                    })}
                  </>
                </AccordionBody>
              </AccordionItem>
            </Accordion>
          )}

          {weeklyData &&
            weeklyData?.days?.map((item, i) => {
              return (
                <Accordion open={index} toggle={toggle} key={i}>
                  <AccordionItem className="mb-3">
                    <AccordionHeader targetId={`accordionItem${i}`}>
                      {moment(item.date).format("dddd | DD-MM-yyyy")}
                    </AccordionHeader>
                    <AccordionBody accordionId={`accordionItem${i}`}>
                      {item.isOverridden && (
                        <HeaderBanner
                          title={`Please note: this daily has been overridden`}
                          type="warning"
                        />
                      )}

                      <Row>
                        <Col lg={8} sm={8}>
                          <h6>Category</h6>
                        </Col>
                        <Col lg={4} sm={4}>
                          <h6>Amount</h6>
                        </Col>
                      </Row>

                      {item.categories &&
                        item.categories.map((category, i) => {
                          return (
                            <div>
                              <h6 className="mt-3">{category.name}</h6>
                              {category.items.map((item, i) => {
                                return (
                                  <Row className="mb-1" key={i}>
                                    <Col lg={8} sm={8}>
                                      {item.name}
                                    </Col>
                                    <Col lg={4} sm={4}>
                                      {item.value}
                                    </Col>
                                  </Row>
                                );
                              })}
                            </div>
                          );
                        })}
                    </AccordionBody>
                  </AccordionItem>
                </Accordion>
              );
            })}

          <Formik
            enableReinitialize={true}
            initialValues={{
              purchaseOrder: weeklyData.purchaseOrder ?? "",
              comment: "",
              signature: "",
              isApproved: false,
              needsPurchaseOrder: weeklyData.needsPurchaseOrder,
            }}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {({ handleSubmit, setFieldValue, values }) => (
              <>
                {weeklyData?.comments && (
                  <>
                    <h5>Comments</h5>
                    {weeklyData.comments?.map((comment) => (
                      <MessageTimeLineItem
                        userName={comment.userName}
                        value={comment.value}
                        creationTime={new Date(comment.creationTime)}
                      />
                    ))}
                  </>
                )}

                <Form onSubmit={handleSubmit} className="pb-5">
                  {weeklyData.needsPurchaseOrder && (
                    <Row>
                      <Col sm={12}>
                        <div className="mb-3">
                          <AppTextInput
                            type="text"
                            name="purchaseOrder"
                            label="Purchaseorder (is generated when you approve the overview)"
                            disabled={true}
                          />
                        </div>
                      </Col>
                    </Row>
                  )}

                  {canProcessData() && (
                    <Row className="mb-4">
                      <Col sm={12}>
                        <AppTextAreaInput
                          name="comment"
                          label="Comments"
                          rows="6"
                          disabled={!canProcessData()}
                        />
                      </Col>
                    </Row>
                  )}

                  {canProcessData() && (
                    <>
                      <Row>
                        <Col className="mb-4">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            value="1"
                            onClick={(e) => {
                              setDataEntryComplete(e.target.checked);
                            }}
                            id="dataEntryCorrect"
                          />

                          <label
                            for="dataEntryCorrect"
                            className="form-check-label ms-2"
                          >
                            To my knowledge, this form has been filled in
                            correctly
                          </label>
                        </Col>
                      </Row>

                      <AppSignaturepad
                        setFieldValue={setFieldValue}
                        name="signature"
                        label="Signed by (official title)"
                      />

                      <Row className="text-end pt-5">
                        <Col>
                          <AppButton
                                             variant="cancel"
                                             title="Decline daily"

                            disabled={
                              saving ||
                              !dataEntryComplete ||
                              !weeklyData.isReadyForSignOff ||
                              !values.signature
                            }
                            loading={saving}
                            className="me-3"
                            onClick={(e) => {
                              setFieldValue("isApproved", false);
                              handleSubmit(e);
                            }}
                          >
                            Decline overview
                          </AppButton>
                          <AppButton
                            title="Approve"
                            variant="submit"
                            disabled={
                              saving ||
                              !dataEntryComplete ||
                              !weeklyData.isReadyForSignOff ||
                              !values.signature
                            }
                            loading={saving}
                            onClick={(e) => {
                              setFieldValue("isApproved", true);
                              handleSubmit(e);
                            }}
                          >
                            Approve overview
                          </AppButton>
                        </Col>
                      </Row>
                    </>
                  )}
                </Form>
              </>
            )}
          </Formik>
        </>
      )}
    </div>
  );
};

export default CmApproveDataWeekly;
