import { useMutation, UseMutationResult } from "@tanstack/react-query";
import {
  ExportNightAuditDataByCustomRangeInput,
  GetTenantDashboardData,
} from "./../types/index";
import apiClientV2 from "./apiClientV2";
import http from "./httpService";

const apiEndpoint = "/services/app/TenantWeeklyDashboard";

export function getStatistics(
  projectIds: number[],
  year: number,
  week: number,
  weekPart: number
) {
  return http.get<GetTenantDashboardData>(apiEndpoint + "/GetStatistics", {
    params: { projectIds, year, week, weekPart },
  });
}
export function getComments(
  projectId: number,
  year: number,
  week: number,
  weekPart: number
) {
  return http.get<Comment[]>(apiEndpoint + "/GetComments", {
    params: { projectId, year, week, weekPart },
  });
}

export function GetRegularNightAuditReport(
  projectId: number,
  dateFrom: Date,
  dateTill: Date
) {
  return http.get("/services/app/DailyExportService/GetNightAuditExport", {
    params: { projectId, dateFrom, dateTill },
    headers: {
      "Content-Type": "application/json",
    },
    responseType: "blob",
    timeout: 30000,
  });
}

export const getRegularNightAuditReportV2 = async (
  input: ExportNightAuditDataByCustomRangeInput
): Promise<Blob> => {
  const response = await apiClientV2.get<Blob>("/ExportNightAudit", {
    params: input,
    headers: {
      "Content-Type": "application/json",
    },
    responseType: "blob",
    timeout: 30000,
  });

  return response.data;
};

export const useGetRegularNightAuditReportV2 = (): UseMutationResult<
  Blob,
  Error,
  ExportNightAuditDataByCustomRangeInput
> => {
  return useMutation<Blob, Error, ExportNightAuditDataByCustomRangeInput>({
    mutationFn: getRegularNightAuditReportV2,
  });
};
