import { TenantContextType, TenantSessionData } from "../types/index";
import React from "react";
import { useState } from "react";

export const TenantContext = React.createContext<TenantContextType | null>(
  null
);

const TenantProvider = ({ children, sessionData }) => {
  const tenant = sessionData as TenantSessionData;

  return (
    <TenantContext.Provider value={{ tenant }}>
      {children}
    </TenantContext.Provider>
  );
};

export default TenantProvider;
