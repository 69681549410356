import { AFASLogDto } from "../types";
import http from "./httpService";

const apiEndpoint = "/services/app/AfasLog";

export function GetLogs(
    maxResultCount: number,
    skipCount: number
)
{
        return http.get<AFASLogDto>(apiEndpoint + "/GetAllAfasLogRecord", { 
        params: { maxResultCount, skipCount, },

        });
};