// ** React Imports
import { Link } from "react-router-dom";

// ** Reactstrap Imports
import { CardText, Form, Button, Spinner, CardTitle, Col } from "reactstrap";

// ** Styles
import * as Yup from "yup";
import { Formik } from "formik";
import { useState } from "react";
import React from "react";
import AppTextInput from "../components/forms/AppTextInput";
import AppButton from "../components/Common/AppButton";
import { Row } from "react-bootstrap";
import { sendPasswordResetCode } from "../../services/accountService";
import { SendPasswordResetCodeInput } from "../../types";
import Message from "../../utility/notifications/Message";
import routeNames from "../../router/routeNames";

const validationSchema = Yup.object().shape({
  emailAddress: Yup.string().email().required().label("Emailadres"),
  tenantName: Yup.string().label("Organisation name"),
});

const ForgotPassword = () => {
  const [saving, setSaving] = useState(false);
  const [pwResetEmailSent, setpwResetEmailSent] = useState(false);
  const [email, setEmail] = useState("");

  const handleSubmit = async (input: SendPasswordResetCodeInput) => {
    setSaving(true);

    await sendPasswordResetCode(input)
      .then(() => {
        setEmail(input.emailAddress);
        setSaving(false);
        setpwResetEmailSent(true);
      })
      .catch(() => {
        setSaving(false);
        Message(
          "Sending password reset email failed",
          "Please try again later",
          "error"
        );
      });
  };

  return (
    <>
      {!pwResetEmailSent ? (
        <>
          <h1 className="h3 mb-3 fw-bold text-primary">Forgot password?</h1>

          <p>
            Enter your email address below and you will receive instructions in
            the mail to reset your password
          </p>

          <Formik
            enableReinitialize={true}
            initialValues={{
              emailAddress: "",
              tenantName: "",
            }}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {({ handleSubmit }) => (
              <Form className="auth-login-form mt-2" onSubmit={handleSubmit}>
                <>
                  <div className="mb-1">
                    <AppTextInput
                      name="tenantName"
                      label="Organisation name"
                      type="text"
                    />
                  </div>

                  <div className="mb-1">
                    <AppTextInput
                      name="emailAddress"
                      label="Emailadres"
                      required
                      type="email"
                    />
                  </div>

                  <AppButton
                    loading={saving}
                    disabled={saving}
                    type="submit"
                    title="Reset password"
                    buttonSize="lg"
                    className="w-100 mt-4"
                  />

                  <Row className="row mt-2">
                    <Col className="d-flex justify-content-center">
                      <Link to={routeNames.login}>Back to login</Link>
                    </Col>
                  </Row>
                </>
              </Form>
            )}
          </Formik>
        </>
      ) : (
        <>
          <h1 className="h3 mb-3 fw-bold text-primary">
            Instructions underway
          </h1>

          <p>
            An email has been sent to <strong>{email}</strong> with instructions
            to reset your password. You may close this page now.
          </p>
        </>
      )}
    </>
  );
};

export default ForgotPassword;
