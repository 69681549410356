import { CopyConfigurationInput } from "./../types/configuration";
import { CreateOrUpdateAdditionalItemInput } from "./../types/index";
import { GetAdditionalItemsData } from "../types";
import http from "./httpService";

const apiEndpoint = "/services/app/AdditionalItem";

export function getAdditionalItems(projectId: number, showHistory: boolean) {
  return http.get<GetAdditionalItemsData>(apiEndpoint + "/GetAdditionalItems", {
    params: { projectId, showHistory },
  });
}

export function createOrUpdateAdditionalItem(
  input: CreateOrUpdateAdditionalItemInput
) {
  return http.post(apiEndpoint + "/CreateOrUpdate", input);
}

export function deleteAdditionalItem(id) {
  return http.delete(apiEndpoint + `/Delete?Id=${id}`);
}

export function copyConfiguration(input: CopyConfigurationInput) {
  return http.post(apiEndpoint + "/CopyConfiguration", input);
}
