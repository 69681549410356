import * as Constants from "../utility/constants";

export const isUserLoggedIn = () => localStorage.getItem("userData");
export const getUserData = () => JSON.parse(localStorage.getItem("userData"));

export const isInRole = (role) => {
  // Retrieve user data from local storage and parse it
  const userDataString = localStorage.getItem("userData");
  if (!userDataString) return false; // Early return if userData is not found

  const userData = JSON.parse(userDataString);
  if (!userData.roles) return false; // Early return if roles are not found

  // Normalize user roles to lower case
  const userRoles = userData.roles.map((r) => r.toLowerCase());

  // Check if the input is an array and normalize roles if so
  if (Array.isArray(role)) {
    const normalizedInputRoles = role.map((r) => r.toLowerCase());
    // Check if any of the input roles exist in userRoles
    return normalizedInputRoles.some((r) => userRoles.includes(r));
  } else {
    // Normalize the single role input to lower case and check
    return userRoles.includes(role.toLowerCase());
  }
};

export const isLoggedInInSide = (menuItemSide) => {
  if (menuItemSide === Constants.ApplicationSides.BOTHSIDES) return true;
  const userData = getUserData();
  let loggedInUserSide = 0;
  if (userData?.isHost) {
    loggedInUserSide = Constants.ApplicationSides["HOSTSIDE"];
  } else {
    loggedInUserSide = Constants.ApplicationSides["TENANTSIDE"];
  }

  if (menuItemSide === loggedInUserSide) return true;

  return false;
};

export const isHostUser = () => {
  const userData = getUserData();
  return userData?.isHost;
};

export const isMonitorUser = () => {
  if (isHostUser() && isInRole("Monitor")) return true;
  return false;
};

export const isFinanceUser = () => {
  if (isHostUser() && isInRole("Finance")) return true;
  return false;
};

export const isAdminUser = () => {
  if (isHostUser() && isInRole("Admin")) return true;
  return false;
};

export const isCustomerAdminUser = () => {
  if (isInRole("Customer admin")) return true;
  return false;
};

export const isMODUser = () => {
  if (!isHostUser() && isInRole("MOD")) return true;
  return false;
};

export const isAreaManagerUser = () => {
  if (!isHostUser() && isInRole("Hotel manager")) return true;
  return false;
};

export const isHFCAreaManagerUser = () => {
  if (!isHostUser() && isInRole("HFC Area manager")) return true;
  return false;
};

export const isHouseKeeperUser = () => {
  if (!isHostUser() && isInRole("Housekeeper")) {
    return true;
  } else {
    return false;
  }
};

export const isViewerUser = () => {
  if (!isHostUser() && isInRole("Viewer")) {
    return true;
  } else {
    return false;
  }
};

export const isOrganisationAdminUser = () => {
  if (!isHostUser() && isInRole("Admin")) return true;
  return false;
};

Object.byString = function (o, s) {
  try {
    if (!s) return;
    s = s.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
    s = s.replace(/^\./, ""); // strip a leading dot
    const a = s.split(".");
    for (let i = 0, n = a.length; i < n; ++i) {
      const k = a[i];
      if (k in o) {
        o = o[k];
      } else {
        return;
      }
    }
    return o;
  } catch {
    return;
  }
};
