import { faRotateBack, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import Select from "react-select";
import { Button, Col, Input, Row } from "reactstrap";
import routeNames from "../../router/routeNames";
import { getProjects } from "../../services/projectService";
import { deleteUser, getUsers } from "../../services/userService";
import Message from "../../utility/notifications/Message";
import Notify from "../../utility/notifications/Notify";
import { isHostUser } from "../../utility/Utils";
import PageTitle from "../components/Common/PageTitle";
import { getRolesForDropdown } from "./../../services/userService";
import { TenantContext } from "../../context/tenantContext";
import { sendPasswordResetCode } from "../../services/accountService";

const Users = () => {
  const { tenant } = useContext(TenantContext);

  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [roleFilter, setRoleFilter] = useState([]);
  const [projects, setProjects] = useState([]);
  const [projectFilter, setProjectFilter] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    async function fetchUsers(projectIds, roleIds) {
      const response = await getUsers(projectIds, roleIds);
      setUsers(response.data.result.items);
    }

    async function fetchRoles() {
      const roles = await getRolesForDropdown();
      if (roles) setRoles(roles.data.result.items);
    }

    async function fetchProjects() {
      const projects = await getProjects();

      let options = projects.data.result.items.map(function (project) {
        return { value: project.id, label: project.name };
      });

      if (options) setProjects(options);
    }

    fetchUsers();
    fetchRoles();
    fetchProjects();
  }, []);

  async function handleChangeRole(value) {
    const roleIds = value.map((v) => v.value);
    setRoleFilter(roleIds);

    const response = await getUsers(projectFilter, roleIds, searchTerm);
    setUsers(response.data.result.items);
  }

  async function handleProjectChange(value) {
    const projectIds = value.map((v) => v.value);
    setProjectFilter(projectIds);

    const response = await getUsers(projectIds, roleFilter, searchTerm);
    setUsers(response.data.result.items);
  }

  async function handleChange(event) {
    setSearchTerm(event.target.value);

    const response = await getUsers(projectFilter, roleFilter, searchTerm);
    setUsers(response.data.result.items);
  }

  const handleDelete = async (user) => {
    Message(
      "Are you sure?",
      `Do you want to delete user ${user.userName}?`,
      "warning"
    ).then(async (result) => {
      if (result.isConfirmed) {
        await deleteUser(user.id)
          .then(async (res) => {
            if (res.status === 200) {
              Notify.success(`User ${user.userName} has been deleted!`);
              const response = await getUsers();
              setUsers(response.data.result.items);
            }
          })
          .catch((err) => {
            if (err.response) {
              Message(
                err.response.data.error.message,
                err.response.data.error.details,
                "error"
              );
            }
          });
      }
    });
  };

  const handleResetPassword = async (user) => {
    Message(
      "Are you sure?",
      `Do you want to send a reset password email to ${user.userName}?`,
      "warning"
    ).then(async (result) => {
      if (result.isConfirmed) {
        await sendPasswordResetCode({
          emailAddress: user.emailAddress,
          tenantName: tenant.tenancyName,
        })
          .then(async (res) => {
            if (res.status === 200) {
              Notify.success(
                `A password reset mail has been sent to ${user.userName}!`
              );
            }
          })
          .catch((err) => {
            if (err.response) {
              Message(
                err.response.data.error.message,
                err.response.data.error.details,
                "error"
              );
            }
          });
      }
    });
  };

  return (
    <div>
      <>
        <PageTitle title="User overview" />
        {users && (
          <>
            <Row className="pb-1">
              <Col xs={12} className="d-flex justify-content-end mb-2">
                <Input
                  placeholder="Search for user..."
                  className="me-2"
                  onChange={(event) => handleChange(event)}
                />
              </Col>
            </Row>
            <Row className="pb-3">
              <Col lg={6} sm={6}>
                <h6>Rollen</h6>
                <Select isMulti options={roles} onChange={handleChangeRole} />
              </Col>
              {!isHostUser() && (
                <Col lg={6} sm={6}>
                  <h6>Projecten</h6>
                  <Select
                    isMulti
                    options={projects}
                    onChange={handleProjectChange}
                  />
                </Col>
              )}
            </Row>

            <Row>
              <DataTable
                pagination
                responsive
                columns={[
                  {
                    name: "userName",
                    selector: (row) => row.userName,
                    sortable: true,
                    cell: (row) => (
                      <Link to={`/users/edit/${row.id}`}>{row.userName}</Link>
                    ),
                  },
                  {
                    name: "Name",
                    selector: (row) => row.fullName,
                    sortable: true,
                  },
                  {
                    name: "Is active",
                    sortable: true,
                    cell: (row) => (row.isActive ? "Yes" : "No"),
                  },
                  {
                    name: "Emailaddress",
                    sortable: true,
                    cell: (row) => row.emailAddress,
                  },
                  {
                    name: "Roles",
                    sortable: true,
                    cell: (row) => row.roleNames.join(", "),
                  },
                  {
                    name: "Actions",
                    cell: (row) => (
                      <>
                        <Button
                          onClick={() => handleDelete(row)}
                          size="sm"
                          color="danger"
                        >
                          <FontAwesomeIcon icon={faTrash} size="xs" />
                        </Button>

                        <div className="ms-2">
                          <Button
                            onClick={() => handleResetPassword(row)}
                            size="sm"
                            color="warning"
                          >
                            <FontAwesomeIcon icon={faRotateBack} size="xs" />
                          </Button>
                        </div>
                      </>
                    ),
                  },
                ]}
                data={users}
              />
            </Row>
          </>
        )}

        <Row>
          <Col>
            <Link to={routeNames.user_create}>
              <Button type="button" color="primary" size="lg">
                Create new user
              </Button>
            </Link>
          </Col>
        </Row>
      </>
    </div>
  );
};

export default Users;
