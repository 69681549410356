import { useContext, useState } from "react";
import PageTitle from "../Common/PageTitle";
import { ProjectListItem, TenantContextType } from "../../../types";
import { Col, Row } from "react-bootstrap";
import ProjectCard from "../Common/ProjectCard";
import React from "react";
import ProjectTable from "../Common/ProjectTable";
import { TenantContext } from "../../../context/tenantContext";
import { Input } from "reactstrap";
import AppButton from "../Common/AppButton";

interface ProjectSelectionPage {
  projects: ProjectListItem[] | undefined;
  pageTitle: string;
  onProjectSelection: (id: number) => void;
  buttonText: string
  onHandleSearch: (event: string) => void;
}

const ProjectSelectionPage = ({
  projects,
  pageTitle,
  onProjectSelection,
  buttonText,
  onHandleSearch,
}: ProjectSelectionPage) => {
  const [useTableView, setUseTableView] = useState<boolean>(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onHandleSearch(event.target.value);
  };

  const { tenant: tenantContext } = useContext(
    TenantContext
  ) as TenantContextType;

  return (
    <div>
      <>
        <PageTitle title={pageTitle} />

        <Row className="pb-2">
          <Col xs={6}></Col>
          <Col xs={6} className="d-flex justify-content-end mb-2">
            <Input
              placeholder="Search for project name..."
              className="me-2"
              onChange={(event) => handleChange(event)}
            />
            <AppButton
              variant={useTableView ? "table" : "square"}
              size="sm"
              className="w-50"
              onClick={() => setUseTableView(!useTableView)}
              title={useTableView ? "Use grid view" : "Use table view"}
            />
          </Col>
        </Row>
        {projects && projects?.length > 0 ? (
          <Row>
            {!useTableView ? (
              <>
                {projects?.map((project, i) => {
                  return (
                    <Col lg={4} sm={6} key={`project-${i}`} className="mb-2">
                      <ProjectCard
                        name={project.name}
                        buttonText={buttonText}
                        city={project.city}
                        hfcId={project.hfcId}
                        isConfigurationNotValid={
                          tenantContext?.isStayplease
                            ? !project.isStaypleaseConfigurationValid
                            : false
                        }
                        onButtonClick={() => onProjectSelection(project.id)}
                      />
                    </Col>
                  );
                })}
              </>
            ) : (
              <ProjectTable
                data={projects}
                onButtonClick={(id) => {
                  onProjectSelection(id);
                }}
              />
            )}
          </Row>
        ) : (
          "There are no available projects for you, please contact an administrator to activate projects."
        )}
      </>
    </div>
  );
};

export default ProjectSelectionPage;
