import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, CardHeader, Col, Form, Row } from "reactstrap";
import AppModal from "../components/Common/AppModal";
import AppSelectInput from "../components/forms/AppSelectInput";
import { copyConfiguration, getProjects } from "../../services/projectService";
import { DropdownItem } from "../../types";
import { Formik } from "formik";
import * as Yup from "yup";
import { copyConfiguration as addItemCopyConfiguration } from "../../services/additionalItemService";
import { CopyConfigurationInput } from "../../types/configuration";
import Notify from "../../utility/notifications/Notify";
import Message from "../../utility/notifications/Message";

const validationSchema = Yup.object().shape({
  copyToProjectId: Yup.number().required().label("Copy to project"),
  copyFromProjectId: Yup.number().required().label("Copy from project"),
});

interface CopyConfigurationModalProps {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  type: "ArticleConfig" | "AdditionalHoursConfig";
  projectId: number;
  onSavingDone: () => void;
}

const CopyConfigurationModal = ({
  showModal,
  setShowModal,
  type,
  projectId,
  onSavingDone,
}: CopyConfigurationModalProps) => {
  const [projectItems, setProjectItems] = useState<DropdownItem[]>();
  const [saving, setSaving] = useState(false);

  async function getProjectsForDropdown() {
    const result = await getProjects();

    let options = result.data.result.items.map(function (project) {
      return { value: project.id, label: project.name } as DropdownItem;
    }) as DropdownItem[];

    setProjectItems(options);
  }

  useEffect(() => {
    getProjectsForDropdown();
  }, []);

  const handleSubmit = async (input: CopyConfigurationInput) => {
    setSaving(true);

    ((await type) === "AdditionalHoursConfig"
      ? addItemCopyConfiguration(input)
      : copyConfiguration(input)
    )
      .then((res) => {
        if (res.status === 200) {
          setSaving(false);
          Notify.success(`Configuratie gekopieerd`);
          onSavingDone();
          setShowModal(false);
        }
      })
      .catch((err) => {
        if (err.response) {
          setSaving(false);

          Message(
            err.response.data.error.message,
            err.response.data.error.details,
            "error"
          );
        }
      });
  };

  const initialValues: any = {
    copyToProjectId: projectId,
  };

  return (
    <AppModal
      show={showModal}
      title="Copy configuration"
      handleHide={() => {
        setShowModal(!showModal);
      }}
    >
      <>
        <div className="d-flex mb-3">
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {({ handleSubmit, values }) => (
              <Form onSubmit={handleSubmit} className="mt-2 w-100">
                <Row className="mb-4">
                  <AppSelectInput
                    label="Select the project to copy data from"
                    name="copyFromProjectId"
                    options={projectItems}
                    callback={undefined}
                  />
                </Row>

                <Row className="text-end">
                  <Col>
                    <Button
                      color="secondary"
                      size="lg"
                      type="button"
                      disabled={saving}
                      onClick={() => setShowModal(!showModal)}
                      className="me-2"
                    >
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      size="lg"
                      type="submit"
                      disabled={saving}
                    >
                      Copy data
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </div>
      </>
    </AppModal>
  );
};

export default CopyConfigurationModal;
