import React from "react";

interface PageProps {
  loading?: boolean;
  loadingMessage?: string;
  [x: string]: any;
}

const Page = ({ loading, loadingMessage, children }: PageProps) => {
  return (
    <>
      {loading ? (
        <div className="text-center my-3">
          <div className="spinner-border" role="status"></div>
          <span className="ms-3">
            <strong>{loadingMessage}</strong>
          </span>
        </div>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

export default Page;
