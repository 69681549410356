import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import UserContext from "../../context/userContext";
import routeNames from "../../router/routeNames";
import { backToImpersonator } from "../../services/accountService";
import useJwt from "../../services/useJwt";

const ToImpersonator = () => {
  const userContext = useContext(UserContext);
  const navigate = useNavigate();

  const handleBackToImpersonator = async () => {
    const result = await backToImpersonator();

    if (result) {
      handleImpersonation(result.data.result.impersonationToken);
    }
  };

  const handleImpersonation = async (impersonationToken) => {
    useJwt.impersonatedAuthenticate(impersonationToken).then((res) => {
      if (res.status === 200) {
        const data = {
          accessToken: res.data.result.accessToken,
          ...res.data.result.user,
          isHost: true,
          refreshToken: res.data.result.refreshToken,
          isImpersonated: false,
        };

        userContext.onLoggedIn(data);
      }
    });
  };

  useEffect(() => {
    handleBackToImpersonator();
    navigate(routeNames.hostDashboard);
  }, []);

  return null;
};

export default ToImpersonator;
