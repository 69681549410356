import { useField, useFormikContext } from "formik";
import * as _ from "lodash";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import AppFormLabel from "../../../views/components/forms/AppFormLabel";

const RenderQuillReact = ({ name, label, required }) => {
  const { setFieldValue, errors, touched } = useFormikContext();
  const [field] = useField(name);
  const [editorLoaded, setEditorLoaded] = useState(false);

  useEffect(() => {
    const initializeQuill = async () => {
      const modules = {
        toolbar: [
          [{ header: [1, 2, false] }],
          ["bold", "italic", "underline", "strike", "blockquote"],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
        ],
        clipboard: {
          matchVisual: false,
        },
      };

      const formats = [
        "header",
        "font",
        "size",
        "bold",
        "italic",
        "underline",
        "blockquote",
        "list",
        "bullet",
        "link",
      ];

      setEditorLoaded({ modules, formats });
    };

    initializeQuill();
  }, []); // Add any dependencies if needed

  if (!editorLoaded) {
    return <p>Loading...</p>;
  }

  return (
    <>
      {label && (
        <AppFormLabel for="floatingInput" label={label} required={required} />

        // <AppLabel label={label} sx={{ fontSize: 14 }} required={required} />
      )}

      <ReactQuill
        modules={editorLoaded.modules}
        formats={editorLoaded.formats}
        value={field.value}
        onChange={(value) => setFieldValue(name, value)}
        onBlur={() => {
          // Manually trigger Formik's onBlur event
          field.onBlur(field.name);
        }}
      />
      {_.get(touched, name) && _.get(errors, name) && (
        <span style={{ color: "red" }}>{_.get(errors, name)}</span>
      )}
    </>
  );
};

export default RenderQuillReact;
