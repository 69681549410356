import http from "./httpService";

const apiEndpoint = "/services/app/Project";
const itemApiEndpoint = "/services/app/Item";

//TODO: add server sided sorting, filtering, etc.
export function getProjects() {
  return http.get(apiEndpoint + "/GetProjects?maxResultCount=500");
}

export function createOrUpdate(project) {
  return http.post(apiEndpoint + "/CreateOrUpdate", project);
}

export function deleteProject(id) {
  return http.delete(apiEndpoint + `/delete?id=${id}`);
}

export function getProject(id) {
  return http.get(apiEndpoint + `/getforedit?id=${id}`);
}

export function getMyProjects(searchTerm) {
  return http.get(apiEndpoint + `/GetProjectsForUser?searchTerm=` + searchTerm);
}

export function getProjectItemsForDropdown(projectIds) {
  return http.get(itemApiEndpoint + `/GetItemsForDropdown?maxResultCount=500`, {
    params: { projectIds },
  });
}

export function getProjectAdditionalItemsForDropdown(projectIds) {
  return http.get(
    itemApiEndpoint + `/GetAdditionalItemsForDropdown?maxResultCount=500`,
    {
      params: { projectIds },
    }
  );
}

export function getProjectStaypleaseItemsForDropdown(projectIds) {
  return http.get(
    itemApiEndpoint + `/GetStayPleaseItemsForDropdown?maxResultCount=500`,
    {
      params: { projectIds },
    }
  );
}

export function getProjectArticleItems(projectId) {
  return http.get(
    itemApiEndpoint + `/GetItems?projectId=${projectId}` + `&maxResultCount=500`
  );
}

export function copyConfiguration(input) {
  return http.post(itemApiEndpoint + "/CopyConfiguration", input);
}

export function createOrUpdateArticleItem(data) {
  return http.post(itemApiEndpoint + "/CreateOrUpdate", data);
}

export function deleteProjectArticleConfiguration(id) {
  return http.delete(itemApiEndpoint + `/Delete?Id=${id}`);
}

export function getApproversForProject(projectId) {
  return http.get(apiEndpoint + `/GetApproversForProject?id=${projectId}`);
}
