import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Button, Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
import PageTitle from "../components/Common/PageTitle";
import Message from "../../utility/notifications/Message";
import Notify from "../../utility/notifications/Notify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { deleteProject, getProjects } from "../../services/projectService";
import moment from "moment";
import routeNames from "../../router/routeNames";
import Page from "../components/Page";

const Projects = () => {
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    async function fetchProjects() {
      setLoading(true);
      const response = await getProjects();
      setProjects(response.data.result.items);
      setLoading(false);
    }
    fetchProjects();
  }, []);

  const handleDelete = async (project) => {
    Message(
      "Are you sure?",
      `Do you want to delete project ${project.name}?`,
      "warning"
    ).then(async (result) => {
      if (result.isConfirmed) {
        await deleteProject(project.id)
          .then(async (res) => {
            if (res.status === 200) {
              Notify.success(`Project ${project.name} has been deleted!`);
              const response = await getProjects();
              setProjects(response.data.result.items);
            }
          })
          .catch((err) => {
            if (err.response) {
              Message(
                err.response.data.error.message,
                err.response.data.error.details,
                "error"
              );
            }
          });
      }
    });
  };

  return (
    <Page loading={loading} loadingMessage="Retrieving project data">
      <PageTitle title="Project overview" />
      {projects && (
        <Row>
          <DataTable
            pagination
            responsive
            columns={[
              {
                name: "name",
                selector: (row) => row.name,
                sortable: true,
                cell: (row) => (
                  <Link to={`${routeNames.project_edit}/${row.id}`}>
                    {row.name}
                  </Link>
                ),
              },
              {
                name: "HFC id",
                selector: (row) => row.hfcId,
                sortable: true,
              },
              {
                name: "City",
                selector: (row) => row.city,
                sortable: true,
              },
              {
                name: "Creation time",
                sortable: true,
                cell: (row) => moment(row.creationTime).format("L"),
              },
              {
                name: "Actions",
                cell: (row) => (
                  <Button
                    onClick={() => handleDelete(row)}
                    size="sm"
                    color="danger"
                  >
                    <FontAwesomeIcon icon={faTrash} size="xs" />
                  </Button>
                ),
              },
            ]}
            data={projects}
          />
        </Row>
      )}

      <Row>
        <Col>
          <Link to={routeNames.project_create}>
            <Button type="button" color="primary" size="lg">
              Create new project
            </Button>
          </Link>
        </Col>
      </Row>
    </Page>
  );
};

export default Projects;
