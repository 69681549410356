import React from "react";
import RenderQuillReact from "../../../utility/helpers/quill/renderQuillReact";

export interface WYSIWYGInputProps {
  name: string;
  label: string;
  required?: boolean;
}

const WYSIWYGInput = ({ name, label, required }: WYSIWYGInputProps) => {
  return <RenderQuillReact name={name} label={label} required={required} />;
};

export default WYSIWYGInput;
