import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { NextWeekPrevOutput } from "../../../types";
import { calculateNextPrevWeek } from "../../../utility/DateFilters";
import DatePickerFilter from "./DateFilterPickr";

interface DateTogglerProps {
  onPreviousDate: (isPrevious: boolean) => void;
  onNextDate: (isPrevious: boolean) => void;
  title?: any;
  handleDatePickerChange?: (date: any) => void;
  handleDateToggle?: (input: NextWeekPrevOutput) => void;
  weekPart?: number;
  week?: number;
  hasSecondWeekPart?: boolean;
  year?: number;
}

const DateToggler = ({
  onPreviousDate,
  onNextDate,
  handleDatePickerChange,
  title,
  weekPart,
  week,
  hasSecondWeekPart,
  year,
  handleDateToggle,
}: DateTogglerProps) => {
  const handleNextPrevDate = (isPrevious: boolean) => {
    if (handleDateToggle) {
      const newData = calculateNextPrevWeek(
        isPrevious,
        week!,
        weekPart!,
        hasSecondWeekPart || false,
        year!
      );

      handleDateToggle(newData);
    }
  };

  return (
    <div className="d-flex flex-column align-items-center">
      <h4 className="d-flex align-items-center mb-5">
        <FontAwesomeIcon
          icon={faAngleLeft as any}
          onClick={() => {
            if (handleDateToggle) {
              handleNextPrevDate(true);
            } else {
              onPreviousDate(true);
            }
          }}
        />
        <div className="datetoggler-text">{title && title}</div>
        <FontAwesomeIcon
          icon={faAngleRight as any}
          onClick={() => {
            if (handleDateToggle) {
              handleNextPrevDate(false);
            } else {
              onNextDate(false);
            }
          }}
        />
      </h4>
      {handleDatePickerChange && (
        <DatePickerFilter
          handleChange={(date) => handleDatePickerChange(date)}
        />
      )}
    </div>
  );
};

export default DateToggler;
