import { faEye, faPencil, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Link, useNavigate } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import routeNames from "../../router/routeNames";
import {
  deleteReleaseNote,
  getReleaseNotes,
} from "../../services/releaseNoteService";
import { ReleaseNoteListDto } from "../../types";
import Message from "../../utility/notifications/Message";
import Notify from "../../utility/notifications/Notify";
import PageTitle from "../components/Common/PageTitle";
import { isAdminUser } from "../../utility/Utils";

const ReleaseNotes = () => {
  const navigate = useNavigate();
  const [releaseNotes, setReleaseNotes] = useState<ReleaseNoteListDto[]>([]);

  async function fetchReleaseNotes() {
    await getReleaseNotes().then((result) => {
      setReleaseNotes(result.data.result.items);
    });
  }

  useEffect(() => {
    fetchReleaseNotes();
  }, []);

  const handleDelete = async (releaseNote: ReleaseNoteListDto) => {
    Message(
      "Are you sure?",
      `Do you want to delete release note version ${releaseNote.versionNumber}?`,
      "warning"
    ).then(async (result) => {
      if (result.isConfirmed) {
        await deleteReleaseNote(releaseNote.id)
          .then(async (res) => {
            if (res.status === 200) {
              Notify.success(
                `Release notes ${releaseNote.versionNumber} has been deleted!`
              );
              fetchReleaseNotes();
            }
          })
          .catch((err) => {
            if (err.response) {
              Message(
                err.response.data.error.message,
                err.response.data.error.details,
                "error"
              );
            }
          });
      }
    });
  };

  return (
    <div>
      <>
        <PageTitle title="Release notes" />
        {releaseNotes && (
          <Row>
            <DataTable
              pagination
              responsive
              columns={[
                {
                  name: "Version number",
                  selector: (row) => row.versionNumber,
                  sortable: true,
                },
                {
                  name: "Release date",
                  selector: (row) =>
                    moment(row.releaseDate).format(
                      "dddd | DD-MM-yyyy | hh:mm:ss"
                    ),
                  sortable: true,
                },
                {
                  name: "title",
                  selector: (row) => row.title,
                  sortable: true,
                  cell: (row) => (
                    <Link to={isAdminUser() ?  `${routeNames.release_notes_edit}/${row.id}` :   `${routeNames.release_notes_details}/${row.id}`}>
                      {row.title}
                    </Link>
                  ),
                },
                {
                  name: "Actions",
                  cell: (row) => (
                    <>
                      {isAdminUser() ? (
                        <>
                          <Button
                            onClick={() =>
                              navigate(
                                `${routeNames.release_notes_edit}/${row.id}`
                              )
                            }
                            size="sm"
                            color="primary"
                            style={{ marginRight: 5 }}
                          >
                            <FontAwesomeIcon icon={faPencil} size="xs" />
                          </Button>

                          <Button
                            onClick={() => handleDelete(row)}
                            size="sm"
                            color="danger"
                          >
                            <FontAwesomeIcon icon={faTrash} size="xs" />
                          </Button>
                        </>
                      ) : (
                        <Button
                          onClick={() =>
                            navigate(
                              `${routeNames.release_notes_details}/${row.id}`
                            )
                          }
                          size="sm"
                          color="primary"
                        >
                          <FontAwesomeIcon icon={faEye} size="xs" />
                        </Button>
                      )}
                    </>
                  ),
                },
              ]}
              data={releaseNotes}
            />
          </Row>
        )}

        {isAdminUser() && (
          <Row>
            <Col>
              <Link to={routeNames.release_notes_create}>
                <Button type="button" color="primary" size="lg">
                  Add release note
                </Button>
              </Link>
            </Col>
          </Row>
        )}
      </>
    </div>
  );
};

export default ReleaseNotes;
