import React, { Fragment } from "react";
import { toast } from "react-toastify";

const options = {
  position: toast.POSITION.BOTTOM_RIGHT,
};

const Notify = {
  success(title, message) {
    return toast.success(
      title,
      // <Fragment>
      //   {title && (
      //     <div className="toastify-header">
      //       <div className="title-wrapper">
      //         <h6 className="toast-title font-weight-bold">{title}</h6>
      //       </div>
      //     </div>
      //   )}
      //   {message && (
      //     <div className="toastify-body">
      //       <span>{message}</span>
      //     </div>
      //   )}
      // </Fragment>,
      options
    );
  },
  error(title, message) {
    return toast.error(title, options);
  },
  warning(title, message) {
    return toast.warning(title, options);
  },
};

export default Notify;
