import { NextWeekPrevOutput } from "../types";

export const calculateNextPrevWeek = (
  isPrevious: boolean,
  week: number,
  weekPart: number,
  hasSecondWeekPart: boolean,
  year: number
): NextWeekPrevOutput => {
  let newYear = year!;
  let newWeek = week!;
  let newWeekPart = 1;

  if (week === 1 && weekPart === 1 && isPrevious) newYear = year - 1;

  //Check if we need to go to the next or previous year
  if (!isPrevious) {
    const lastWeekOfYearNumber = getWeekNumber(new Date(`${year}-12-31`));

    if (week === 1) {
      return {
        week: 2,
        year: newYear,
        weekPart: 1,
      };
    }

    if (
      lastWeekOfYearNumber == week &&
      (weekPart === 2 || !hasSecondWeekPart)
    ) {
      return {
        week: 1,
        year: year + 1,
        weekPart: 1,
      };
    }
  }

  if (isPrevious) {
    if (week === 1) {
      return {
        week: 52,
        weekPart: newWeekPart,
        year: newYear,
      };
    }

    const weekPartCalc = isWeekSplit(newYear, week! - 1);

    if (weekPart !== 2) {
      newWeek = week! - 1;
      newWeekPart = weekPartCalc ? 2 : 1;
    }

    return {
      week: newWeek,
      weekPart: newWeekPart,
      year: newYear,
    };
  } else {
    if ((hasSecondWeekPart || false) && weekPart! === 1) {
      newWeekPart = 2;
    } else {
      newWeek = week! + 1;
    }

    return {
      week: newWeek,
      weekPart: newWeekPart,
      year: newYear,
    };
  }
};

function getWeekNumber(d) {
  // Copy date so don't modify original
  d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
  // Set to nearest Thursday: current date + 4 - current day number
  // Make Sunday's day number 7
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
  // Get first day of year
  var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1)) as any;
  // Calculate full weeks to nearest Thursday
  var weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
  // Return array of year and week number
  return weekNo;
}

function isWeekSplit(year, weekNumber) {
  // Calculate the first day of the year
  const firstDayOfYear = new Date(year, 0, 1);

  // Find the first Monday of the year
  const firstMonday = firstDayOfYear;
  while (firstMonday.getDay() !== 1) {
    firstMonday.setDate(firstMonday.getDate() + 1);
  }

  // Calculate the start and end date of the specified week
  const startDate = new Date(firstMonday);
  startDate.setDate(firstMonday.getDate() + (weekNumber - 1) * 7);
  const endDate = new Date(startDate);
  endDate.setDate(startDate.getDate() + 6);

  // Check if the start and end date are in different months
  return startDate.getMonth() !== endDate.getMonth();
}
