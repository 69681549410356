// ** JWT Service Import
import JwtService from "./authService";

// ** Export Service as useJwt
const jwt = new JwtService({
  loginEndpoint: "/TokenAuth/Authenticate",
  registerEndpoint: "/jwt/register",
  refreshEndpoint: "/TokenAuth/RefreshToken",
  logoutEndpoint: "/jwt/logout",
  impersonateEndpoint: "/TokenAuth/ImpersonatedAuthenticate",

  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: "Bearer",

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: "accessToken",
  storageRefreshTokenKeyName: "refreshToken",
});

export default jwt;

// // ** Core JWT Import
// import useJwt from "@src/@core/auth/jwt/useJwt";

// const { jwt } = useJwt({
//   loginEndpoint: "/TokenAuth/Authenticate",
//   registerEndpoint: "/jwt/register",
//   refreshEndpoint: "/TokenAuth/RefreshToken",
//   logoutEndpoint: "/jwt/logout",

//   // ** This will be prefixed in authorization header with token
//   // ? e.g. Authorization: Bearer <token>
//   tokenType: "Bearer",

//   // ** Value of this property will be used as key to store JWT token in storage
//   storageTokenKeyName: "accessToken",
//   storageRefreshTokenKeyName: "refreshToken",
// });

// export default jwt;
