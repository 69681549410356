import React from "react";
import { useParams } from "react-router-dom";
import SignoffDaily from "./SignoffDaily";

const SignoffSheet = () => {
  const { id } = useParams();

  return (
    <>
      <SignoffDaily id={parseInt(id || "")} />
    </>
  );
};

export default SignoffSheet;
