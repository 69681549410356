import http from "./httpService";

const apiEndpoint = "/services/app/User";

//TODO: add server sided sorting, filtering, etc.
export function getUsers(projectIds, roleIds, searchTerm) {
  return http.get(apiEndpoint + "/GetAll?maxResultCount=500", {
    params: { projectIds, roleIds, searchTerm },
  });
}

export function createUser(tenant) {
  return http.post(apiEndpoint + "/create", tenant);
}

export function updateUser(tenant) {
  return http.put(apiEndpoint + "/update", tenant);
}

export function deleteUser(id) {
  return http.delete(apiEndpoint + `/delete?id=${id}`);
}

export function setOutOfOffice(input) {
  return http.post(apiEndpoint + "/SetOutOfOffice", input);
}

export function getUsersForDropdown() {
  return http.get(
    apiEndpoint + "/GetUsersForOutOfOfficeDropdown?maxResultCount=500",
    {}
  );
}

export function getUser(id) {
  return http.get(apiEndpoint + `/getforedit?id=${id}`);
}
export function getRoles() {
  return http.get(apiEndpoint + "/GetRoles");
}

export function getRolesForDropdown() {
  return http.get("/services/app/Role/GetForDropdown");
}

export function updatePassword(newPassword) {
  return http.post(apiEndpoint + "/ResetPassword", newPassword);
}
