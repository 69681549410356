// ** React Imports
import { Link } from "react-router-dom";

// ** Reactstrap Imports
import { Col } from "reactstrap";

// ** Styles
import React, { useState } from "react";
import { Row } from "react-bootstrap";
import ResetPasswordForm from "../../modules/authentication/ResetPasswordForm";
import routeNames from "../../router/routeNames";

const ResetPassword = () => {
  const [pwResetFinished, setPwResetFinished] = useState(false);

  return (
    <>
      {!pwResetFinished ? (
        <>
          <h1 className="h3 mb-3 fw-bold text-primary">Reset password</h1>

          <p>Create a new password below</p>

          <ResetPasswordForm
            onResettingPasswordDone={() => setPwResetFinished(true)}
          />
        </>
      ) : (
        <>
          <h1 className="h3 mb-3 fw-bold text-primary">Password reset</h1>

          <p>
            Your password has been reset, click the link below to go back to
            login.
          </p>

          <Row className="row mt-2">
            <Col className="d-flex justify-content-center">
              <Link to={routeNames.login}>Back to login</Link>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default ResetPassword;
