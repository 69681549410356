import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import routeNames from "../../router/routeNames";
import { getMyProjects } from "../../services/projectService";
import { ProjectListItem } from "../../types";
import ProjectSelectionPage from "../components/pages/projectSelectionPage";

const CorrectionsStartScreen = () => {
  const navigate = useNavigate();
  const [projects, setProjects] = useState<ProjectListItem[]>();
  const [searchTerm, setSearchTerm] = useState<string>("");

  useEffect(() => {
    async function fetchUserProjects() {
      const projects = await getMyProjects(searchTerm);

      const projectList: ProjectListItem[] = projects.data.result;
      if (projectList && projectList.length === 1) {
        navigate(`${routeNames.sp_corrections}/${projectList[0].id}`);
      } else {
        setProjects(projectList);
      }
    }

    fetchUserProjects();
  }, [navigate, searchTerm]);

  return (
    <ProjectSelectionPage
      projects={projects}
      pageTitle="Corrections"
      buttonText='Open corrections'
      onHandleSearch={(val) => {
        setSearchTerm(val);
      }}
      onProjectSelection={(id) =>
        navigate(`${routeNames.sp_corrections}/${id}`)
      }
    />
  );
};

export default CorrectionsStartScreen;
