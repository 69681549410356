import React from "react";
import { useFormikContext } from "formik";
import { Input } from "reactstrap";
import AppFormError from "./AppFormError";
import AppFormLabel from "./AppFormLabel";
import moment from "moment";

interface AppDateInputProps {
  name: string;
  label?: string;
  required?: boolean;
  [x: string]: any;
}

function AppDateInput({
  name,
  label,
  required = false,
  ...otherProps
}: AppDateInputProps) {
  const { setFieldTouched, setFieldValue, values, errors, touched } =
    useFormikContext();

  const setInputValue = (value) => {
    if (value) {
      return moment(value).format("YYYY-MM-DD");
    }
  };

  return (
    <>
      <AppFormLabel for="floatingInput" label={label} required={required} />

      <Input
        value={setInputValue(Object.byString(values, name))}
        onBlur={() => setFieldTouched(name)}
        onChange={(event) => setFieldValue(name, event.target.value)}
        {...otherProps}
        type="date"
      />

      <AppFormError
        visible={Object.byString(touched, name)}
        error={Object.byString(errors, name)}
      ></AppFormError>
    </>
  );
}

export default AppDateInput;
