import { TenantDtoPagedResultDto } from "./../types/apiTypes";
import { CreateOrUpdateTenantInput } from "./../types/index";
import http from "./httpService";

const apiEndpoint = "/services/app/Tenant";

//TODO: add server sided sorting, filtering, etc.
export function getTenants() {
  return http.get<{
    result: TenantDtoPagedResultDto;
  }>(apiEndpoint + "/GetAll?maxResultCount=500");
}

export function createTenant(tenant) {
  return http.post(apiEndpoint + "/create", tenant);
}

export function updateTenant(tenant: CreateOrUpdateTenantInput) {
  return http.put(apiEndpoint + "/update", tenant);
}

export function deleteTenant(id) {
  return http.delete(apiEndpoint + `/delete?id=${id}`);
}

export function getTenant(id) {
  return http.get(apiEndpoint + `/get?id=${id}`);
}

export function getUsersForTenant(id, searchTerm) {
  return http.get(
    apiEndpoint + `/GetTenantUsers?tenantId=${id}&searchTerm=${searchTerm}`
  );
}
