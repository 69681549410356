import React, { useState, useEffect, useMemo } from "react";
import { useFormikContext } from "formik";
import { Input } from "reactstrap";
import AppFormError from "./AppFormError";
import AppFormLabel from "./AppFormLabel";
import CreatableSelect from "react-select/creatable";
import * as _ from "lodash";

function AppFormTagBox({
  name,
  width,
  label,
  options,
  required = false,
  isMultiple = true,
  ...otherProps
}) {
  const { setFieldTouched, setFieldValue, values, errors, touched } =
    useFormikContext();

  const [value, setValue] = useState(options ?? []);
  const [inputValue, setInputValue] = useState();

  useEffect(() => {
    setValue(options ?? []);
  }, [options]);

  const onChange = (option) => {
    setValue(option);
    setFieldValue(name, option);
  };

  const createOption = (label) => ({
    label,
  });

  const handleInputChange = (value) => {
    setInputValue(value);
  };

  const handleKeyDown = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        setValue([...value, createOption(inputValue)]);

        setFieldValue(name, [...value, createOption(inputValue)]);

        event.preventDefault();

        setInputValue("");
    }
  };

  return (
    <>
      {<AppFormLabel label={label} required={required} />}
      {values && (
        <>
          <CreatableSelect
            inputValue={inputValue}
            isClearable
            isMulti={isMultiple}
            onChange={onChange}
            options={options}
            value={value}
            menuIsOpen={false}
            onBlur={() => setFieldTouched(name)}
            {...otherProps}
            onKeyDown={handleKeyDown}
            onInputChange={handleInputChange}
          />
        </>
      )}
      {!values && (
        <Input
          onBlur={() => setFieldTouched(name)}
          onChange={(event) => setFieldValue(name, event.value)}
          {...otherProps}
        />
      )}
      <AppFormError
        visible={_.get(touched, name)}
        error={_.get(errors, name)}
      ></AppFormError>
    </>
  );
}

export default AppFormTagBox;
