import axios from "axios";
import jwtDefaultConfig from "./jwtDefaultConfig";
import { RefreshTokenResult } from "../types/apiTypes";

// Create an axios instance
const apiClientV2 = axios.create({
  baseURL: `${process.env.REACT_APP_API_CONTROLLER_BASE_URL}`, // Set a base URL for all requests
  headers: {
    "Content-Type": "application/json",
  },
});

// Function to retrieve token
async function getToken() {
  const accessToken = localStorage.getItem(
    jwtDefaultConfig.storageTokenKeyName
  ) as string;

  return accessToken;
}

async function refreshToken() {
  try {
    const refreshToken = localStorage.getItem(
      jwtDefaultConfig.storageRefreshTokenKeyName
    ) as string;

    const response = await axios.post<RefreshTokenResult>(
      `${apiClientV2.defaults.baseURL}api/TokenAUth/RefreshToken`,
      { token: refreshToken }
    );

    if (response.data) {
      localStorage.setItem(
        jwtDefaultConfig.storageTokenKeyName,
        response.data.accessToken!
      );

      return response.data.accessToken;
    }
  } catch (error) {
    // await userSignOut()
    throw new Error("Failed to refresh token");
  }
}

apiClientV2.interceptors.request.use(
  async (config) => {
    const token = await getToken();
    if (token) {
      config.headers!.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

apiClientV2.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const newAccessToken = await refreshToken();
      apiClientV2.defaults.headers.common["Authorization"] =
        "Bearer " + newAccessToken;
      return apiClientV2(originalRequest);
    } else if (error.response.status === 500) {
      console.error("Server error: ", error.response.data);
      logOut();
    }
    return Promise.reject(error);
  }
);

// Setting up a response interceptor to handle errors globally
apiClientV2.interceptors.response.use(
  (response) => response,
  (error) => {
    const { status, data } = error.response;
    if (status === 401) {
      console.error("Authentication error: ", data.message);
      // Handle 401 errors globally
    }
    // Other status codes can be handled here as well
    return Promise.reject(error);
  }
);

const logOut = () => {
  localStorage.removeItem("userData");
  localStorage.removeItem(jwtDefaultConfig.storageTokenKeyName);
  localStorage.removeItem(jwtDefaultConfig.storageRefreshTokenKeyName);
  location.href = "/login";
};

export default apiClientV2;
