import React from "react";
import { useFormikContext } from "formik";
import { Input } from "reactstrap";
import AppFormError from "./AppFormError";
import Select from "react-select";
import AppFormLabel from "./AppFormLabel";
import * as _ from "lodash";

//https://gist.github.com/hubgit/e394e9be07d95cd5e774989178139ae8
function AppSelectInput({
  name,
  label,
  options,
  required = false,
  isMultiple = false,
  disabled = false,
  callback,
  ...otherProps
}) {
  const { setFieldTouched, setFieldValue, values, errors, touched } =
    useFormikContext();

  const onChange = (option) => {
    if (isMultiple) {
      var value = option.map(function (item) {
        return item.value;
      });

      setFieldValue(name, value);

      if (callback) {
        callback(value);
      }
    } else {
      setFieldValue(name, option.value);
    }
  };

  const getValue = () => {
    if (options) {
      if (isMultiple) {
        return options.filter(function (option) {
          if (_.get(values, name)) {
            return _.get(values, name).indexOf(option.value) >= 0;
          }
        });
      } else {
        return options.filter((option) => option.value === _.get(values, name));
      }
    } else {
      return isMultiple ? [] : "";
    }
  };

  return (
    <>
      {<AppFormLabel label={label} required={required} />}
      {values && (
        <>
          <Select
            isDisabled={disabled}
            isMulti={isMultiple}
            onChange={onChange}
            options={options}
            value={getValue()}
            onBlur={() => setFieldTouched(name)}
            {...otherProps}
          />
        </>
      )}
      {!values && (
        <Input
          onBlur={() => setFieldTouched(name)}
          onChange={(event) => setFieldValue(name, event.value)}
          {...otherProps}
        />
      )}
      <AppFormError
        visible={_.get(touched, name)}
        error={_.get(errors, name)}
      ></AppFormError>
    </>
  );
}

export default AppSelectInput;
