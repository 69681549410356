import { useFormikContext } from "formik";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import SignatureCanvas from "react-signature-canvas";
import { Button } from "reactstrap";
import AppFormLabel from "./AppFormLabel";

export const AppSignaturepad = ({ name, label, required }) => {
  const { setFieldTouched, setFieldValue, values, errors, touched } =
    useFormikContext();
  const [dataEntryComplete, setDataEntryComplete] = useState(false);

  const [width, setWidth] = useState(500);

  //   const [canvasImageUrl, setCanvasImageUrl] = useState([
  //     props.parentSignature || "",
  //   ]);

  let sigCanvas = useRef();
  const signatureContainer = useRef();

  useEffect(() => {
    setWidth(signatureContainer.current.clientWidth);
  });

  const clearCanvas = () => {
    setFieldValue(name, "");
    sigCanvas.current.clear();
  };

  const saveCanvas = async () => {
    if (sigCanvas.current.isEmpty()) return;
    // document.getElementById("parentName").blur();

    setFieldValue(
      name,
      sigCanvas.current.getTrimmedCanvas().toDataURL("image/png")
    );

    // setCanvasImageUrl(
    //   sigCanvas.current.getTrimmedCanvas().toDataURL("image/png")
    // );
  };

  return (
    <div>
      <AppFormLabel for="floatingInput" label={label} required={required} />

      <div>
        <div className={"sigContainer"} ref={signatureContainer}>
          <SignatureCanvas
            ref={sigCanvas}
            canvasProps={{ width: width, height: 300, className: "sigPad" }}
            onEnd={saveCanvas}
          />
        </div>
        <Button
          //   style={{ marginLeft: "5%", marginTop: "2%", marginRight: "2%" }}
          type={"button"}
          onClick={clearCanvas}
          children={"Clear"}
        />
        <br />
        <br />
      </div>
      {/* {!props.disabled && (
        <div>
          <div className={"sigContainer"}>
            <SignatureCanvas
              ref={sigCanvas}
              canvasProps={{ className: "sigPad" }}
              onEnd={saveCanvas}
            />
          </div>
          <Button
            style={{ marginLeft: "5%", marginTop: "2%", marginRight: "2%" }}
            type={"button"}
            onClick={clearCanvas}
            children={"Clear"}
          />
          <br />
          <br />
        </div>
      )} */}

      {/* {canvasImageUrl[0] && (
        <div className={"signatureDisplay"}>
          <img
            src={canvasImageUrl}
            alt={"Guardian Signature"}
            style={{ height: "100%", width: "100%" }}
          />
        </div>
      )} */}
    </div>
  );
};
