import { useState } from "react";
import CustomAccordionItem from "./CustomAccordionItem";
import React from "react";

type item = {
  title: string;
  LeftNavigation?: React.ReactNode;
  RightNavigation?: React.ReactNode;
  id: string;
  body?: React.ReactNode;
};

interface CustomAccordionProps {
  items?: item[];
  handleShowBody?: (id: string) => void;
  open: string;
  handleOpen: (identifier: string) => void;
  titleColor?: string;
}

const CustomAccordion = ({
  items,
  handleOpen,
  open,
  titleColor,
}: CustomAccordionProps) => {
  return (
    <>
      {items &&
        items.map((item) => {
          return (
            <CustomAccordionItem
              titleColor={titleColor}
              key={item.id}
              LeftNavigation={item.LeftNavigation}
              RightNavigation={item.RightNavigation}
              open={open}
              title={item.title}
              handleToggle={handleOpen}
              id={item.id}
              body={item.body}
            />
          );
        })}
    </>
  );
};

export default CustomAccordion;
