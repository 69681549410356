import { Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import UserContext from "../../context/userContext";
import { handleLoggedInRoute } from "../../router/routeHelper";
import routeNames from "../../router/routeNames";
import useJwt from "../../services/useJwt";
import Message from "../../utility/notifications/Message";
import AppButton from "../components/Common/AppButton";
import AppCheckboxInput from "../components/forms/AppCheckboxInput";
import AppTextInput from "../components/forms/AppTextInput";

const validationSchema = Yup.object().shape({
  userNameOrEmailAddress: Yup.string().required().label("Gebruikersnaam"),
  password: Yup.string().required().label("password"),
  rememberClient: Yup.bool().label("Remember me"),
  tenancyName: Yup.string().label("Tenant name"),
});

const Login = () => {
  const userContext = useContext(UserContext);
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (userContext.currentUser) {
      navigate(handleLoggedInRoute());
    }
  }, [userContext.currentUser, navigate]);

  const handleSubmit = (user) => {
    setLoading(true);
    useJwt
      .login(user)
      .then((res) => {
        if (res.status === 200) {
          const data = {
            ...res.data.result.user,
            accessToken: res.data.result.accessToken,
            refreshToken: res.data.result.refreshToken,
            isHost: user.tenancyName ? false : true,
          };

          userContext.onLoggedIn(data).then(() => {
            navigate(handleLoggedInRoute());
            setLoading(false);
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          setLoading(false);
          Message(
            err.response.data.error.message,
            err.response.data.error.details,
            "error"
          );
        }
      });
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        userNameOrEmailAddress: "",
        password: "",
        rememberClient: true,
        tenancyName: "",
      }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ handleSubmit }) => (
        <>
          <h1 className="h3 mb-3 fw-bold text-primary">Login</h1>

          <Form onSubmit={handleSubmit}>
            <div className="mb-3">
              <AppTextInput
                id="organsation"
                type="text"
                name="tenancyName"
                label="Organisation"
              />
            </div>

            <div className="mb-3">
              <AppTextInput
                type="text"
                id="username"
                name="userNameOrEmailAddress"
                label="Emailaddress"
              />
            </div>

            <div className="mb-3">
              <AppTextInput
                id="password"
                type="password"
                name="password"
                label="Password"
              />
            </div>

            <Row className="row mb-4">
              <Col className="d-flex justify-content-between">
                <AppCheckboxInput name="rememberMe" label="Remember me" />
                <Link to={routeNames.forgot_password}>Forgot password?</Link>
              </Col>
            </Row>

            <AppButton
              loading={loading}
              disabled={loading}
              type="submit"
              title="Sign in"
              buttonSize="lg"
              className="w-100"
            />
          </Form>
        </>
      )}
    </Formik>
  );
};

export default Login;
