import { saveAs } from "file-saver";
import { Form, Formik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import * as Yup from "yup";
import { getAfasExportByRange } from "../../services/hostDashboardService";
import { getMyProjects } from "../../services/projectService";
import { ExportAfasDataByCustomRangeInput } from "../../types";
import AppButton from "../components/Common/AppButton";
import AppModal from "../components/Common/AppModal";
import AppDateInput from "../components/forms/AppDateInput";
import AppSelectInput from "../components/forms/AppSelectInput";
interface CustomFilterExportModalProps {
  showExportModal: boolean;
  handleHide: () => void;
}

const exportCustomRangeValidationScheme = Yup.object().shape({
  projectIds: Yup.array().required().label("Projects"),
  itemIds: Yup.array().label("Items"),
  additionalItemIds: Yup.array().label("Items"),
  staypleaseItemIds: Yup.array().label("Items"),
  dateFrom: Yup.date().required().label("Date from"),
  dateTill: Yup.date().required().label("Date till"),
});

const CustomFilterExportModal = ({
  showExportModal,
  handleHide,
}: CustomFilterExportModalProps) => {
  const [exportingData, setExportingData] = useState(false);
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    getProjectDataForFilter();
  }, []);

  async function getProjectDataForFilter() {
    const result = await getMyProjects("");

    let options = result.data.result.map(function (project) {
      return { value: project.id, label: project.name };
    });

    setProjects(options);
  }

  const exportCustomRange = (data: ExportAfasDataByCustomRangeInput) => {
    setExportingData(true);
    getAfasExportByRange(
      data.projectIds,
      data.dateFrom,
      data.dateTill,
      data.itemIds,
      data.additionalItemIds,
      data.staypleaseItemIds
    ).then((result) => {
      setExportingData(false);
      saveAs(
        new Blob([result.data]),
        `Export-AFAS-${moment(data.dateFrom).format("DD-MM-YYYY")}-${moment(
          data.dateTill
        ).format("DD-MM-YYYY")}.xlsx`
      );
    });
  };

  return (
    <AppModal
      show={showExportModal}
      title="Export with custom filters"
      handleHide={() => handleHide()}
    >
      <>
        <Formik
          enableReinitialize={true}
          initialValues={
            {
              projectIds: [],
              itemIds: [],
              staypleaseItemIds: [],
              additionalItemIds: [],
            } as any
          }
          onSubmit={exportCustomRange}
          validationSchema={exportCustomRangeValidationScheme}
        >
          {({ handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Col lg={12}>
                  <AppSelectInput
                    label="Projects"
                    name="projectIds"
                    isMultiple
                    required
                    options={projects}
                    callback={undefined}
                  />
                </Col>
              </Row>

              <Row className="mb-3">
                <Col lg={6}>
                  <AppDateInput name="dateFrom" label="Date from" required />
                </Col>
                <Col lg={6}>
                  <AppDateInput name="dateTill" label="Date till" required />
                </Col>
              </Row>

              <Row className="text-end">
                <Col>
                  <AppButton
                    title="Export"
                    color="primary"
                    size="lg"
                    loading={exportingData}
                    disabled={exportingData}
                    type="submit"
                  />
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </>
    </AppModal>
  );
};

export default CustomFilterExportModal;
