import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import routeNames from "../../router/routeNames";
import { getMyProjects } from "../../services/projectService";
import { ProjectListItem } from "../../types";

import ProjectSelectionPage from "../components/pages/projectSelectionPage";

const CmDataApproval = () => {
  const navigate = useNavigate();
  const [projects, setProjects] = useState<ProjectListItem[]>();
  const [searchTerm, setSearchTerm] = useState<string>("");

  const { role } = useParams();

  const getNavigationUrl = (projectId: number): string => {
    if (role === "MOD") {
      return `${routeNames.cm_data_approval}/${role}/${projectId}`;
    } else {
      return `${routeNames.cm_data_approval_weekly}/${role}/${projectId}`;
    }
  };

  useEffect(() => {
    async function fetchUserProjects() {
      const projects = await getMyProjects(searchTerm);

      if (
        projects.data.result &&
        projects.data.result.length === 1 &&
        searchTerm == ""
      ) {
        navigate(getNavigationUrl(projects.data.result[0].id));
      }

      setProjects(projects.data.result);
    }

    fetchUserProjects();
  }, [navigate, searchTerm]);

  return (
    <ProjectSelectionPage
      projects={projects}
      buttonText='Open overview'
      onHandleSearch={(val) => {
        setSearchTerm(val);
      }}
      pageTitle="Data approval - project overview"
      onProjectSelection={(id) => navigate(getNavigationUrl(id))}
    />
  );
};

export default CmDataApproval;
