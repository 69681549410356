import React from "react";
import AppModal from "../../components/Common/AppModal";
import { Form, Formik, FormikProps } from "formik";
import { ExportNightAuditDataByCustomRangeInput } from "../../../types";
import { useGetRegularNightAuditReportV2 } from "../../../services/tenantDashboardService";
import { GetNightAuditReport } from "../../../services/stayPleaseService";
import * as saveAs from "file-saver";
import moment from "moment";
import * as Yup from "yup";
import { Col, Row } from "react-bootstrap";
import AppSelectInput from "../../components/forms/AppSelectInput";
import AppDateInput from "../../components/forms/AppDateInput";
import AppButton from "../../components/Common/AppButton";
import { DropdownDto } from "../../../types/apiTypes";

const exportCustomRangeValidationScheme = Yup.object().shape({
  projectId: Yup.number().required().label("Project"),
  dateFrom: Yup.date().required().label("Date from"),
  dateTill: Yup.date().required().label("Date till"),
});

interface ExportHFCOverviewDialogProps {
  showExportModal: boolean;
  isStaypleaseTenant: boolean;
  projects?: DropdownDto[];
  handleHide: () => void;
}

const ExportHFCOverviewDialog = ({
  showExportModal,
  isStaypleaseTenant,
  projects,
  handleHide,
}: ExportHFCOverviewDialogProps) => {
  const downloadRegularNightAuditReport = useGetRegularNightAuditReportV2();

  const fetchNightAuditReport = async (
    input: ExportNightAuditDataByCustomRangeInput
  ) => {
    if (isStaypleaseTenant) {
      GetNightAuditReport(input.projectId, input.dateFrom, input.dateTill).then(
        (result) => {
          saveAs(
            new Blob([result.data]),
            `HFC-Period-Overview-${moment(input.dateFrom).format(
              "DD-MM-YYYY"
            )}-${moment(input.dateTill).format("DD-MM-YYYY")}.pdf`
          );
        }
      );
    } else {
      const result = await downloadRegularNightAuditReport.mutateAsync({
        dateFrom: input.dateFrom,
        dateTill: input.dateTill,
        projectId: input.projectId,
      });

      if (result) {
        saveAs(result),
          `HFC-Period-Overview-${moment(input.dateFrom).format(
            "DD-MM-YYYY"
          )}-${moment(input.dateTill).format("DD-MM-YYYY")}.pdf`;
      }
    }
  };

  return (
    <AppModal
      show={showExportModal}
      title="Export HFC period overview"
      handleHide={() => handleHide()}
    >
      <>
        <Formik
          enableReinitialize={true}
          initialValues={
            {
              projectId: 0,
            } as ExportNightAuditDataByCustomRangeInput
          }
          onSubmit={fetchNightAuditReport}
          validationSchema={exportCustomRangeValidationScheme}
        >
          {({
            handleSubmit,
          }: FormikProps<ExportNightAuditDataByCustomRangeInput>) => (
            <Form onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Col lg={12}>
                  <AppSelectInput
                    label="Projects"
                    name="projectId"
                    required
                    options={projects}
                    callback={undefined}
                  />
                </Col>
              </Row>

              <Row className="mb-3">
                <Col lg={6}>
                  <AppDateInput name="dateFrom" label="Date from" required />
                </Col>
                <Col lg={6}>
                  <AppDateInput name="dateTill" label="Date till" required />
                </Col>
              </Row>

              <Row className="text-end">
                <Col>
                  <AppButton
                    title="Export"
                    color="primary"
                    size="lg"
                    loading={downloadRegularNightAuditReport.isPending}
                    disabled={downloadRegularNightAuditReport.isPending}
                    type="submit"
                  />
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </>
    </AppModal>
  );
};

export default ExportHFCOverviewDialog;
