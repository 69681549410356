import React from "react";
import { useNavigate } from "react-router-dom";
import AppButton from "./AppButton";

interface PageTitleProps {
  title?: string | null;
  customStyles?: string;
  hideBackButton?: boolean;
  children?: JSX.Element | JSX.Element[];
}

const PageTitle = ({
  title,
  customStyles,
  hideBackButton,
  children,
}: PageTitleProps) => {
  const navigate = useNavigate();

  return (
    <>
      {!hideBackButton && (
        <AppButton variant="back" onClick={() => navigate(-1)} title={"Back"} />
      )}

      <h1
        className={[
          "my-5 text-center " + (customStyles ? customStyles : ""),
        ].join()}
      >
        {title}
        {children}
      </h1>
    </>
  );
};

export default PageTitle;
