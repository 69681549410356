import React from "react";
import { Label } from "reactstrap";
import { boolean, string } from "yup";

interface AppFormLabelProps {
  label: string;
  required?: boolean;
  [x: string]: any;
}

const AppFormLabel = ({
  label,
  required = false,
  ...rest
}: AppFormLabelProps) => {
  return (
    <>
      {label && (
        <Label className="form-label" {...rest}>
          {label}
          {required && (
            <span className="ms-1 text-danger" title={`${label} is verplicht`}>
              <strong>*</strong>
            </span>
          )}
        </Label>
      )}
    </>
  );
};

export default AppFormLabel;
