import {
  GetAdditionalRequestOverviewData,
  ProcessAdditionalRequestOverviewInput,
} from "./../types/index";
import http from "./httpService";

const apiEndpoint = "/services/app/AdditionalRequestOverview";

export function getAdditionalRequestOverview(
  projectId: number,
  week: number,
  weekPart: number,
  year: number
) {
  return http.get<GetAdditionalRequestOverviewData>(apiEndpoint + "/Get", {
    params: {
      projectId,
      week,
      weekPart,
      year,
    },
  });
}

export function submitAdditionalRequestOverviewData(
  input: ProcessAdditionalRequestOverviewInput
) {
  return http.post(apiEndpoint + "/Process", input);
}
