import React from "react";
// import ErrorMessage from "./ErrorMessage";
import { useFormikContext } from "formik";
import { Input } from "reactstrap";
import AppFormError from "./AppFormError";
import AppFormLabel from "./AppFormLabel";

function AppCheckboxInput({
  name,
  label,
  required = false,
  callback = null,
  ...otherProps
}) {
  const { setFieldTouched, setFieldValue, values, errors, touched } =
    useFormikContext();

  const handleOnChange = (event) => {
    setFieldValue(name, event.target.checked);

    if (callback) {
      callback(event.target.checked);
    }
  };

  return (
    <>
      <div className="form-check">
        <Input
          type="checkbox"
          id={name}
          checked={Object.byString(values, name)}
          onBlur={() => setFieldTouched(name)}
          onChange={(event) => handleOnChange(event)}
        />

        {<AppFormLabel for={name} label={label} required={required} />}

        <AppFormError
          visible={touched[name]}
          error={errors[name]}
        ></AppFormError>
      </div>
    </>
  );
}

export default AppCheckboxInput;
