import http from "./httpService";

const apiEndpoint = "/services/app/UserRoleExport";

export function getUserRolesForTenant(id) {
  return http.get(apiEndpoint + `/Export?tenantId=${id}`, {
    responseType: "blob",
    headers: {
      "Content-Type": "application/json",
    },
    timeout: 30000,
  });
}
