import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React, { useContext, useEffect, useState } from "react";
import {
  BrowserRouter,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import "./App.scss";
import TenantProvider, { TenantContext } from "./context/tenantContext";
import UserContext from "./context/userContext";
import routeNames from "./router/routeNames";
import jwtDefaultConfig from "./services/jwtDefaultConfig";
import { getCurrentTenantInformation } from "./services/sessionService";
import useJwt from "./services/useJwt";
import { TenantContextType, TenantSessionData } from "./types";
import {
  isAdminUser,
  isAreaManagerUser,
  isCustomerAdminUser,
  isFinanceUser,
  isHFCAreaManagerUser,
  isHostUser,
  isHouseKeeperUser,
  isInRole,
  isMODUser,
  isMonitorUser,
  isOrganisationAdminUser,
  isViewerUser,
} from "./utility/Utils";
import AdditionalHoursStartScreen from "./views/additionalHours";
import AdditionalHoursSheet from "./views/additionalHours/AdditionalHoursSheet";
import AdditionalHoursOverview from "./views/additionalHoursOverview/AdditionalHoursOverview";
import AdditionalHoursOverviewSheet from "./views/additionalHoursOverview/AdditionalHoursSheet";
import AfasLogs from "./views/afasLogs";
import ForgotPassword from "./views/authentication/ForgotPassword";
import Login from "./views/authentication/Login";
import Logout from "./views/authentication/Logout";
import ResetPassword from "./views/authentication/ResetPassword";
import ToImpersonator from "./views/authentication/ToImpersonator";
import CmDataApproval from "./views/cmDataApproval";
import CmApproveDataWeekly from "./views/cmDataApproval/AppoveDateWeekly";
import CmApproveData from "./views/cmDataApproval/ApproveData";
import AuthLayout from "./views/components/AuthLayout";
import Navbar from "./views/components/Common/Navbar";
import Profile from "./views/components/Common/Profile";
import ProtectedRoute from "./views/components/Common/ProtectedRoute";
import ReleaseNoteModal from "./views/components/releaseNotes/ReleaseNoteModal";
import CorrectionsStartScreen from "./views/corrections";
import StayPleaseCorrectionsSheet from "./views/corrections/CorrectionsSheet";
import DailySheet from "./views/daily/DailySheet";
import DailyStartScreen from "./views/daily/index";
import DataApprovalOverview from "./views/dataApproval";
import ApproveData from "./views/dataApproval/ApproveData";
import FaqPage from "./views/faq";
import DailyMatrix from "./views/hostDailyMatrix";
import HostDashboard from "./views/hostDashboard";
import Projects from "./views/projects";
import CreateOrEditProject from "./views/projects/createOrEdit";
import ReleaseNotes from "./views/releaseNotes";
import CreateOrEditReleaseNote from "./views/releaseNotes/CreateOrEdit";
import ReleaseNoteDetails from "./views/releaseNotes/details";
import SignoffStartScreen from "./views/signoff";
import SignOffSheet from "./views/signoff/SignoffSheet";
import ItemConfiguration from "./views/spItemConfiguration/ItemConfiguration";
import SwitchUserOverview from "./views/switchUser";
import AreaManagerDashboard from "./views/tenantDashboard/AreaManagerDashboard";
import Tenants from "./views/tenants";
import CreateOrEditTenant from "./views/tenants/CreateOrEdit";
import CreateOrEditUser from "./views/users/CreateOrEdit";
import Users from "./views/users/index";
import ActivateAccount from "./views/authentication/ActivateAccount";

const App = () => {
  const queryClient = new QueryClient();

  const [userData, setUserData] = useState();
  const [sessionData, setSessionData] = useState<TenantSessionData>();
  const [showReleaseNoteDialog, setShowReleaseNoteDialog] = useState(false);

  const getTenantSessionData = async () => {
    const result = await getCurrentTenantInformation();
    const sessionResult = result.data.result as TenantSessionData;

    if (sessionResult) {
      setSessionData(sessionResult);
    }
  };

  useEffect(() => {
    const userData = useJwt.getUser();
    if (userData) {
      setUserData(JSON.parse(userData));
      getTenantSessionData();
    }
  }, []);

  const handleLoggedIn = async (data) => {
    localStorage.setItem("userData", JSON.stringify(data));
    localStorage.setItem(
      jwtDefaultConfig.storageRefreshTokenKeyName,
      data.refreshToken
    );
    localStorage.setItem(
      jwtDefaultConfig.storageTokenKeyName,
      data.refreshToken
    );

    await getTenantSessionData().then(() => {
      setUserData(data);
    });
  };

  useEffect(() => {
    if (userData && sessionData) {
      if (sessionData.hasNotSeenReleaseNotes) {
        setShowReleaseNoteDialog(true);
      }
    }
  }, [userData, sessionData]);

  const handleLoggedOut = () => {
    setUserData(undefined);
  };

  return (
    <QueryClientProvider client={queryClient}>
      <TenantProvider sessionData={sessionData}>
        <UserContext.Provider
          value={{
            currentUser: userData,
            onLoggedIn: handleLoggedIn,
            onLoggedOut: handleLoggedOut,
          }}
        >
          <BrowserRouter>
            <Content userData={userData} />

            <ReleaseNoteModal
              show={showReleaseNoteDialog}
              handleHide={() => setShowReleaseNoteDialog(false)}
            />
          </BrowserRouter>
        </UserContext.Provider>
      </TenantProvider>
    </QueryClientProvider>
  );
};

function Content({ userData }) {
  const navigate = useNavigate();

  const location = useLocation();
  const [displayLocation, setDisplayLocation] = useState(location);
  const [transitionStage, setTransistionStage] = useState("fadeIn");
  const { tenant: tenantContext } = useContext(
    TenantContext
  ) as TenantContextType;

  useEffect(() => {
    if (location !== displayLocation) setTransistionStage("fadeOut");
  }, [location, displayLocation]);

  const getRedirectPathForRole = () => {
    if (isCustomerAdminUser()) {
      return routeNames.users;
    }

    if (isHostUser() || isAdminUser()) {
      return routeNames.tenants;
    }

    if (!isHostUser() && isHouseKeeperUser()) {
      return routeNames.daily;
    }

    if (isAreaManagerUser()) {
      return routeNames.cm_data_approval + "/HotelManager";
    }
  };

  useEffect(() => {
    // Redirect only if at the base URL and userData is loaded
    if (location.pathname === "/" && userData) {
      const path = getRedirectPathForRole();
      // Redirect if the path is different from the current one
      if (path !== location.pathname) {
        navigate(path || "");
      }
    }
  }, [location.pathname, navigate, userData]);

  return (
    <>
      {userData && <Navbar />}

      {transitionStage && displayLocation && (
        <div
          className={`${transitionStage}`}
          onAnimationEnd={() => {
            if (transitionStage === "fadeOut") {
              setTransistionStage("fadeIn");
              setDisplayLocation(location);
            }
          }}
        >
          <Routes location={displayLocation}>
            <Route path="/" element={<AuthLayout />}>
              <Route index element={<Login />} />

              <Route path={routeNames.login} element={<Login />} />
              <Route
                path={routeNames.forgot_password}
                element={<ForgotPassword />}
              />
              <Route
                path={routeNames.account_reset_password}
                element={<ResetPassword />}
              />

              <Route
                path={routeNames.account_activate}
                element={<ActivateAccount />}
              />

              <Route path="*" element={<Login />} />
            </Route>

            <Route path={routeNames.logout} element={<Logout />} />
            <Route
              path={routeNames.to_impersonator}
              element={<ToImpersonator />}
            />

            {userData && (
              <Route element={<ProtectedRoute />}>
                <Route path={routeNames.faq} element={<FaqPage />} />
                <Route path={routeNames.profile} element={<Profile />} />
                <Route
                  path={routeNames.switchUser}
                  element={<SwitchUserOverview />}
                />

                <Route
                  path={routeNames.release_notes}
                  element={<ReleaseNotes />}
                />

                <Route
                  path={`${routeNames.release_notes_details}/:id`}
                  element={<ReleaseNoteDetails />}
                />

                {(isInRole("Admin") || isCustomerAdminUser()) && (
                  <>
                    <Route
                      path={routeNames.user_create}
                      element={<CreateOrEditUser />}
                    />
                    <Route
                      path={`${routeNames.user_edit}:id`}
                      element={<CreateOrEditUser />}
                    />
                    {<Route path={routeNames.users} element={<Users />} />}
                  </>
                )}
                <>
                  <Route
                    path={`${routeNames.additional_hours_overview}:role`}
                    element={<AdditionalHoursOverview />}
                  />
                  <Route
                    path={`${routeNames.additional_hours_overview}:role/:id`}
                    element={<AdditionalHoursOverviewSheet />}
                  />
                </>

                {isHostUser() && (
                  <>
                    {(isMonitorUser() || isFinanceUser()) && (
                      <>
                        {" "}
                        <Route
                          path={routeNames.hostDashboard}
                          element={<HostDashboard />}
                        />
                        <Route
                          path={routeNames.matrixHostDashboard}
                          element={<DailyMatrix />}
                        />
                      </>
                    )}

                    {isAdminUser() && (
                      <>
                        <Route
                          path={`${routeNames.release_notes_edit}/:id`}
                          element={<CreateOrEditReleaseNote />}
                        />

                        <Route
                          path={routeNames.release_notes_create}
                          element={<CreateOrEditReleaseNote />}
                        />

                        <Route
                          path={routeNames.afas_logs}
                          element={<AfasLogs />}
                        />
                        <Route
                          path={routeNames.hostDashboard}
                          element={<HostDashboard />}
                        />
                        <Route
                          path={routeNames.matrixHostDashboard}
                          element={<DailyMatrix />}
                        />
                        <Route
                          path={routeNames.tenants}
                          element={<Tenants />}
                        />
                        <Route
                          path={routeNames.tenant_create}
                          element={<CreateOrEditTenant />}
                        />
                        <Route
                          path={`${routeNames.tenant_edit}/:id`}
                          element={<CreateOrEditTenant />}
                        />
                      </>
                    )}
                  </>
                )}

                {((!isHostUser() && isHouseKeeperUser()) || isViewerUser()) && (
                  <>
                    <Route
                      path={routeNames.daily}
                      element={<DailyStartScreen />}
                    />
                    <Route
                      path={`${routeNames.daily}/:id`}
                      element={<DailySheet />}
                    />

                    {tenantContext?.isStayplease && (
                      <>
                        <Route
                          path={routeNames.sp_corrections}
                          element={<CorrectionsStartScreen />}
                        />

                        <Route
                          path={`${routeNames.sp_corrections}/:id`}
                          element={<StayPleaseCorrectionsSheet />}
                        />
                      </>
                    )}

                    <Route
                      path={routeNames.area_manager_dashboard}
                      element={<AreaManagerDashboard />}
                    />

                    <Route
                      path={routeNames.additional_hours}
                      element={<AdditionalHoursStartScreen />}
                    />
                    <Route
                      path={`${routeNames.additional_hours}/:id`}
                      element={<AdditionalHoursSheet />}
                    />
                  </>
                )}

                {!isHostUser() && isOrganisationAdminUser() && (
                  <>
                    <Route path={routeNames.projects} element={<Projects />} />
                    <Route
                      path={routeNames.project_create}
                      element={<CreateOrEditProject />}
                    />
                    <Route
                      path={`${routeNames.project_edit}/:id`}
                      element={<CreateOrEditProject />}
                    />
                    <Route
                      path={routeNames.spitemconfiguration}
                      element={<ItemConfiguration />}
                    />
                  </>
                )}

                {!isHostUser() &&
                  (isAreaManagerUser() || isHFCAreaManagerUser()) && (
                    <>
                      <Route
                        path={routeNames.daily}
                        element={<DailyStartScreen />}
                      />
                      <Route
                        path={`${routeNames.daily}/:id`}
                        element={<DailySheet />}
                      />
                      <Route
                        path={routeNames.data_approval}
                        element={<DataApprovalOverview />}
                      />

                      <>
                        <Route
                          path={`${routeNames.data_approval}/:id`}
                          element={<ApproveData />}
                        />
                      </>

                      <Route
                        path={routeNames.area_manager_dashboard}
                        element={<AreaManagerDashboard />}
                      />
                      {tenantContext?.isStayplease && (
                        <>
                          <Route
                            path={`${routeNames.cm_data_approval}/:role`}
                            element={<CmDataApproval />}
                          />
                          <Route
                            path={`${routeNames.cm_data_approval_weekly}/:role/:id`}
                            element={<CmApproveDataWeekly />}
                          />
                        </>
                      )}
                    </>
                  )}

                {isMODUser() && (
                  <>
                    <Route
                      path={`${routeNames.cm_data_approval}/:role`}
                      element={<CmDataApproval />}
                    />
                    <Route
                      path={`${routeNames.cm_data_approval}/:role/:id`}
                      element={<CmApproveData />}
                    />

                    <Route
                      path={`${routeNames.signoff}/:id`}
                      element={<SignOffSheet />}
                    />

                    <Route
                      path={routeNames.signoff}
                      element={<SignoffStartScreen />}
                    />
                  </>
                )}
              </Route>
            )}
          </Routes>
        </div>
      )}
    </>
  );
}

export default App;
