import { Form, Formik } from "formik";
import { useQuery } from "../../utility/hooks/useQuery";
import { ResetPasswordInput } from "../../types";
import { useState } from "react";
import { resetPassword } from "../../services/accountService";
import React from "react";
import * as Yup from "yup";
import AppTextInput from "../../views/components/forms/AppTextInput";
import AppButton from "../../views/components/Common/AppButton";
import { Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
import routeNames from "../../router/routeNames";
import Message from "../../utility/notifications/Message";
import { PasswordRequirements } from "../../utility/constants";

interface ResetPasswordProps {
  onResettingPasswordDone: () => void;
  submitButtonLabel?: string;
}

const validationSchema = Yup.object().shape({
  c: Yup.string().required(),
  password: Yup.string()
    .matches(PasswordRequirements.expression, PasswordRequirements.label)
    .required()
    .label("Password"),
  passwordConfirmation: Yup.string().required().label("Repeat password"),
});

const ResetPassword = ({
  onResettingPasswordDone,
  submitButtonLabel,
}: ResetPasswordProps) => {
  const [saving, setSaving] = useState(false);

  const query = useQuery();
  const resetCode = query.get("c");

  const handleSubmit = async (input: ResetPasswordInput) => {
    setSaving(true);

    // delete input.passwordConfirmation;

    await resetPassword(input)
      .then(() => {
        setSaving(false);
        onResettingPasswordDone();
      })
      .catch(() => {
        setSaving(false);
        // onResettingPasswordDone();
        Message("Password reset failed", "Please try again later", "error");
      });
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        c: resetCode!,
        password: "",
      }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ handleSubmit }) => (
        <Form className="auth-login-form mt-2" onSubmit={handleSubmit}>
          <>
            <div className="mb-3">
              <AppTextInput
                required
                type="password"
                name="password"
                label="Password"
              />
            </div>

            <div className="mb-3">
              <AppTextInput
                required
                type="password"
                name="passwordConfirmation"
                label="Repeat password"
              />
            </div>

            <AppButton
              loading={saving}
              disabled={saving}
              type="submit"
              title={submitButtonLabel ?? "Reset password"}
              buttonSize="lg"
              className="w-100 mt-4"
            />

            <Row className="row mt-2">
              <Col className="d-flex justify-content-center">
                <Link to={routeNames.login}>Back to login</Link>
              </Col>
            </Row>
          </>
        </Form>
      )}
    </Formik>
  );
};

export default ResetPassword;
